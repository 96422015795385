import React, { Fragment, useState } from "react";

import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import ChatBox from "../ChatBox";
import { useSelector } from "react-redux";
import OnboardingAdminSideBar from "./OnboardingAdminSideBar";

const JobieNav = ({ title }) => {
   const [toggle, setToggle] = useState("");
   const onClick = (name) => setToggle(toggle === name ? "" : name);

   const { user } = useSelector((state) => state.auth);

   return (
      <Fragment>
         <NavHader />
         {/* <SideBar /> */}
         {
            (user?.user_type == 3 || user?.body?.user_type === 3) ? <SideBar /> : <OnboardingAdminSideBar />
         }
         <Header
            onNote={() => onClick("chatbox")}
            onNotification={() => onClick("notification")}
            onProfile={() => onClick("profile")}
            toggle={toggle}
            title={title}
         />
         {/* <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} /> */}
      </Fragment>
   );
};

export default JobieNav;
