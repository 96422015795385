import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { allsupport, reset } from "../../../features/support/supportSlice";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import Nav from "../../layouts/nav";
import ReactPaginate from "react-paginate";
import Spinner from "../../components/Spinner";


function Support() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { support, isLoading, isError, message } = useSelector(
		(state) => state.support
	);
	const getDate = (date) => {
		return dateFormat(date, "yyyy-mm-dd ");
	};
	useEffect(() => {
		if (isError) {
			toast.error(message);
		}
		if (!user) {
			navigate("/admin/login");
		}
		dispatch(allsupport(1));
		return () => reset();
	}, [user, navigate, isError, message, dispatch]);

	const [pageNumber, setPageNumber] = useState(0);

	const userPerPage = 10;
	const pageVisted = pageNumber;
	let pageCount;
	const total_pages = support?.total_pages
	const supportData = support?.body

	const getSupport = () => {
		const formatName = (name) => {
			if (!name) return '';
			const nameParts = name.split(' ');
			if (nameParts.length > 1) {
				return `${nameParts[0][0]}.${nameParts.slice(-1)}`;
			}
			return name;
		};

		const truncateString = (text, maxLength) => {
			return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
		};

		pageCount = total_pages;
		return supportData?.map((data, index) => {
			return (
				<tr role="row" className="odd" key={index}>
					<td>{index + 1 + (pageNumber === 0 ? 0 : pageNumber * 10)}.</td>
					<td>
						<div className="media">
							<div className="media-body text-nowrap">
								<h6
									className="fs-16 mb-0 text-capitalize"
									title={data?.name.length > 20 ? data?.name : ''}
								>
									{truncateString(formatName(data?.name), 10)}
								</h6>
							</div>
						</div>
					</td>
					<td>
						<div className="media">
							<div className="media-body text-nowrap">
								<h6 className="fs-16 mb-0">
									{data.subject}
								</h6>
							</div>
						</div>
					</td>
					<td>
						<div className="media">
							<div className="media-body text-nowrap">
								<h6 className="fs-16 mb-0">
									{data.email}
								</h6>
							</div>
						</div>
					</td>

					<td>
						<div className="media">
							<div className="media-body text-nowrap">
								<h6 className="fs-16 mb-0">
									{getDate(data.createdAt)}
								</h6>
							</div>
						</div>
					</td>
					<td>
						<div className="media">
							<div className="media-body text-nowrap">
								<div className="fs-16 mb-0">
									<h6
										className="fs-16 mb-0 text-capitalize"
										title={data.message.length > 20 ? data.message : ''}
									>
										{truncateString(formatName(data.message), 20)}
									</h6>
								</div>
							</div>
						</div>
					</td>
				</tr>
			);
		});
	};

	const changePage = async (data) => {
		setPageNumber(data.selected);
		dispatch(allsupport(data.selected + 1))
	};

	return (
		<>
			{isLoading ? (
				<Spinner />
			) : (
				<span>
					<Nav />
					<div className="content-body">
						<div className="container-fluid">
							<div className="h-80">
								<div className="d-flex flex-wrap mb-4 row">
									<div className="col-xl-3 col-lg-4 mb-2">
										<h1 className="text-black fs-35 font-w600 mb-3">
											Support
										</h1>
										<h6 className=" fs-16 mb-1">
											{userPerPage} Rows per page
										</h6>
									</div>
								</div>
								<div className="row">
									<div className="col-xl-12">
										<div className="table-responsive">
											<div
												id="example5_wrapper"
												className="dataTables_wrapper no-footer"
											>
												<table
													className="table display mb-4 dataTablesCard card-table dataTable no-footer"
													id="example5"
													role="grid"
													aria-describedby="example5_info"
												>
													<thead>
														<tr role="row">
															<th
																className="sorting"
																tabIndex={0}
																aria-controls="example5"
																rowSpan={1}
																colSpan={1}
																aria-label="ID: activate to sort column ascending"
															>
																Id
															</th>
															<th
																className="sorting"
																tabIndex={0}
																aria-controls="example5"
																rowSpan={1}
																colSpan={1}
																aria-label="Date Applied: activate to sort column ascending"
															>
																Name
															</th>
															<th
																className="sorting"
																tabIndex={0}
																aria-controls="example5"
																rowSpan={1}
																colSpan={1}
																aria-label="Status: activate to sort column ascending"
															>
																Subject
															</th>
															<th
																className="sorting"
																tabIndex={0}
																aria-controls="example5"
																rowSpan={1}
																colSpan={1}
																aria-label="Status: activate to sort column ascending"
																style={{ width: '300px' }}
															>
																Email
															</th>
															<th
																className="sorting"
																tabIndex={0}
																aria-controls="example5"
																rowSpan={1}
																colSpan={1}
																aria-label="Status: activate to sort column ascending"
															>
																Date
															</th>
															<th
																className="sorting"
																tabIndex={0}
																aria-controls="example5"
																rowSpan={1}
																colSpan={1}
																aria-label="Status: activate to sort column ascending"
															>
																Message
															</th>
														</tr>
													</thead>
													<tbody>{getSupport()}</tbody>
												</table>
												<div className="d-flex align-items-center justify-content-between pb-2">
													<div
														className="dataTables_info"
														id="example5_info"
														role="status"
														aria-live="polite"
													>
														Showing {pageVisted + 1}
														of {total_pages} pages
													</div>
													<div
														className="dataTables_paginate paging_simple_numbers"
														id="example5_paginate"
													>
														<ReactPaginate
															previousLabel={"Previous"}
															nextLabel={"Next"}
															pageCount={pageCount}
															onPageChange={changePage}
															containerClassName={"paginationBttns"}
															previousLinkClassName={
																"paginate_button previous previousBttn"
															}
															nextLinkClassName={
																"paginate_button next nextBttn"
															}
															pageLinkClassName={"paginate_button mr-1 ml-1"}
															disabledClassName={"paginationDisabled"}
															activeClassName={"paginationActive"}
															forcePage={pageNumber}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</span>
			)}
		</>
	);
}

export default Support;
