import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap";

const AdminProfile = ({ show, handleClose, user }) => {

    return (
        <Modal show={show} onHide={handleClose} style={{ margin: "auto" }}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Profile
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="d-flex justify-content-center">
                    <img
                        src={user?.image == 'noImg.png' ? "https://www.w3schools.com/howto/img_avatar.png" : user?.image}
                        alt="Avatar"
                        className="avatar"
                        style={{ width: "100px", height: "100px", borderRadius: "50%", marginBottom: "10px" }}
                    />

                </div>

                <div className="d-flex justify-content-between">
                    <p>
                        <strong className='text-black'>Name:</strong> {user?.name}
                    </p>
                    <p>
                        <strong className='text-black'>Email:</strong> {user?.email}
                    </p>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AdminProfile