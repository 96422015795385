import React, { useEffect, useState } from 'react'
import { Button, FormControl, InputGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createOnboardingAdmin, getAllOnboardingAdmins, reset } from '../../../features/onboarding-admin/onboardingAdminSlice'
import { toast } from 'react-toastify';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();


const AddUserModal = ({ handleAddUserModalClose }) => {

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        cpassword: "",
        timezone: timeZone,
    });

    const handleChange = (e) => {
        setFormData((previousState) => ({
            ...previousState,
            [e.target.name]: e.target.value,
        }));
    };

    const dispatch = useDispatch();

    const { createdAdminUser, isError, isSuccess, message } = useSelector((state) => state.onboardingAdmin);

    useEffect(() => {
        if (isError) {
            toast.error(message);
        }
        // if (adminUser && isSuccess) {
        //     toast.success("Onboard Admin Created Successfully");
        // }

        dispatch(reset());
    }, [createdAdminUser, dispatch]);

    const onSubmit = (e) => {
        e.preventDefault();
        const userData = {
            name: formData.name,
            email: formData.email,
            password: formData.password,
            timezone: formData.timezone,
            user_type: 6,
        };
        dispatch(createOnboardingAdmin(userData));
        dispatch(getAllOnboardingAdmins({ pageno: 1 }));
        if (isSuccess) {
            toast.success("Onboard Admin Created Successfully");
        }
        handleAddUserModalClose();
    }

    return (
        <>
            <div className="rounded-3 mb-4" style={{ border: '1px solid #e5e5e5' }}>
                <InputGroup className="">
                    <InputGroup.Text className="bg-transparent text-success">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464l349.5 0c-8.9-63.3-63.3-112-129-112l-91.4 0c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z" fill="#00B094" stroke="#00B094" stroke-width="2" />
                        </svg>
                    </InputGroup.Text>
                    <FormControl
                        className="rounded-3 border-0"
                        id='name'
                        type="name"
                        name='name'
                        placeholder="Enter admin name"
                        onChange={handleChange}
                    />
                </InputGroup>
            </div>
            <div className="rounded-3 my-4" style={{ border: '1px solid #e5e5e5' }}>
                <InputGroup className="">
                    <InputGroup.Text className="bg-transparent">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.5 10.4997C3.5 10.4997 6.65 13.9997 14 13.9997C21.35 13.9997 24.5 10.4997 24.5 10.4997M9.1 22.1663H18.9C20.8602 22.1663 21.8403 22.1663 22.589 21.7849C23.2475 21.4493 23.783 20.9139 24.1185 20.2553C24.5 19.5066 24.5 18.5265 24.5 16.5663V11.433C24.5 9.47282 24.5 8.49273 24.1185 7.74404C23.783 7.08547 23.2475 6.55004 22.589 6.21448C21.8403 5.83301 20.8602 5.83301 18.9 5.83301H9.1C7.13982 5.83301 6.15972 5.83301 5.41103 6.21448C4.75247 6.55004 4.21703 7.08547 3.88148 7.74404C3.5 8.49273 3.5 9.47282 3.5 11.433V16.5663C3.5 18.5265 3.5 19.5066 3.88148 20.2553C4.21703 20.9139 4.75247 21.4493 5.41103 21.7849C6.15972 22.1663 7.13982 22.1663 9.1 22.1663Z" stroke="#00B094" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </InputGroup.Text>
                    <FormControl
                        className="rounded-3 border-0"
                        type="email"
                        id='email'
                        name='email'
                        placeholder="Enter email address"
                        onChange={handleChange}
                    />
                </InputGroup>
            </div>
            <div className="rounded-3 my-4" style={{ border: '1px solid #e5e5e5' }}>
                <InputGroup className="">
                    <InputGroup.Text className="bg-transparent">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 6V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V6M9 11H15C15.5523 11 16 10.5523 16 10V7C16 6.44772 15.5523 6 15 6H9C8.44772 6 8 6.44772 8 7V10C8 10.5523 8.44772 11 9 11ZM5 21H19C20.1046 21 21 20.1046 21 19V16C21 14.8954 20.1046 14 19 14H5C3.89543 14 3 14.8954 3 16V19C3 20.1046 3.89543 21 5 21Z" stroke="#00B094" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <circle cx="7.5" cy="17.5" r="1.5" fill="#00B094" />
                            <circle cx="12" cy="17.5" r="1.5" fill="#00B094" />
                            <circle cx="16.5" cy="17.5" r="1.5" fill="#00B094" />
                        </svg>
                    </InputGroup.Text>
                    <FormControl
                        className="rounded-3 border-0"
                        type="password"
                        id='password'
                        name='password'
                        placeholder="Enter Password"
                        onChange={handleChange}
                    />
                </InputGroup>
            </div>
            <div className="rounded-3 my-4" style={{ border: '1px solid #e5e5e5' }}>
                <InputGroup className="">
                    <InputGroup.Text className="bg-transparent">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 6V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V6M9 11H15C15.5523 11 16 10.5523 16 10V7C16 6.44772 15.5523 6 15 6H9C8.44772 6 8 6.44772 8 7V10C8 10.5523 8.44772 11 9 11ZM5 21H19C20.1046 21 21 20.1046 21 19V16C21 14.8954 20.1046 14 19 14H5C3.89543 14 3 14.8954 3 16V19C3 20.1046 3.89543 21 5 21Z" stroke="#00B094" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <circle cx="7.5" cy="17.5" r="1.5" fill="#00B094" />
                            <circle cx="12" cy="17.5" r="1.5" fill="#00B094" />
                            <circle cx="16.5" cy="17.5" r="1.5" fill="#00B094" />
                        </svg>
                    </InputGroup.Text>
                    <FormControl
                        className="rounded-3 border-0"
                        type="password"
                        id='cpassword'
                        name='cpassword'
                        placeholder="Enter confirm password"
                        onChange={handleChange}
                    />
                </InputGroup>
            </div>

            <div className="text-center">
                <Button
                    type="submit"
                    className="btn btn-success rounded-5 mb-3 mt-3 py-3 btn-md"
                    onClick={onSubmit}
                >
                    Save User
                </Button>
            </div>
        </>
    )
}

export default AddUserModal