import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Dropdown, Form, Stack } from "react-bootstrap";
import Nav from "../../layouts/nav";
import ReactPaginate from "react-paginate";
import Spinner from "../../components/Spinner";
import { getAllTimeSheet } from "../../../features/jobs/jobslice";
import CustomeModal from "../../components/customeModal/CustomeModal";
import dateFormat from "dateformat";
import companyLogo from "../../../images/avatar/1.jpg"


function Appointments() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const data = {
        admin_input: true
    }

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [showPaymentDetails, setShowPaymentDetails] = useState(false)

    const [keyword, setKeyword] = useState("")
    const [selectedValue, setSelectedValue] = useState('');


    const handleShowModal = (jobId) => {
        setIsModalOpen(true)
        // dispatch(getJobDetails({ job_id: jobId }))
    }
    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const { user } = useSelector((state) => state.auth);
    const { templates, isError, message } = useSelector(
        (state) => state?.templates
    );


    const { timeSheet, isLoading } = useSelector((state) => state.jobs);
    const { accesstoken } = useSelector((state) => state?.employers)

    const timeSheetData = ["", "", "", "", "", "", "", "", "", ""]
    const timeSheetTopOptions = timeSheet?.body?.top_options

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedValue(selectedValue);

        const { start_date, end_date } = timeSheetTopOptions?.find(option => option.id === selectedValue);

        dispatch(getAllTimeSheet({
            start_date,
            end_date,
            pageno: pageNumber + 1,
            search: keyword,
            token: accesstoken
        }));
    };

    useEffect(() => {
        if (timeSheetTopOptions?.length === undefined) {
            dispatch(getAllTimeSheet({
                pageno: 1,
                search: keyword,
                token: accesstoken
            }));
        }
    }, []);

    const handleKeywordChange = (event) => {
        setKeyword(event.target.value);
        dispatch(getAllTimeSheet({
            pageno: pageNumber + 1,
            search: keyword,
            token: accesstoken
        }));
    };

    const handleShowMoreClick = () => {
        setShowPaymentDetails(!showPaymentDetails);
    };

    useEffect(() => {
        if (isError) {
            toast.error(message);
        }
        if (!user) {
            navigate("/admin/login");
        }
    }, [user, navigate, isError, message]);

    const [pageNumber, setPageNumber] = useState(0);
    const userPerPage = 10;
    const pageVisted = pageNumber;
    let pageCount;
    const total_pages = templates?.total_pages



    const truncateString = (text, maxLength) => {
        return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    const getDate = (date) => {
        return dateFormat(date, "yyyy-mm-dd ");
    };


    const formatName = (name) => {
        if (!name) return "";
        const nameParts = name.split(" ");
        if (nameParts.length > 1) {
            return `${nameParts[0][0]}.${nameParts.slice(-1)}`;
        }
        return name;
    };

    const getTemlpates = () => {

        pageCount = total_pages;
        return timeSheetData?.map((sheet, index) => {
            return (
                <tr role="row" className="odd" key={index}>
                    <td>{index + 1 + (pageNumber === 0 ? 0 : pageNumber * 10)}.</td>
                    <td>
                        <div className="media">
                            <div className="media-body text-nowrap">
                                <h6 className="fs-16 mb-0">
                                    Mosciski
                                </h6>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="media">
                            <div className="media-body text-nowrap">
                                user@example.com
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="media">
                            <div className="media-body">
                                <h6 className="fs-16 mb-0 mx-1">
                                    June 1, 2020, 08:22 AM
                                </h6>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="media">
                            <div className="media-body">
                                <h6 className=" fs-16 mb-0 text-nowrap">
                                    Intern UI Designer
                                </h6>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="media">
                            <div className="d-flex">
                                {/* <a className=" mr-1" href={"tel:" + data.mobile}> */}
                                <a className=" mr-1" href={"tel:" + 923000000000}>
                                    <svg width="25" height="25" viewBox="0 0 38 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.64453 9.16667C4.64453 23.434 15.3844 35 28.6326 35C29.2303 35 29.823 34.9765 30.4097 34.9302C31.0831 34.8771 31.4198 34.8505 31.7263 34.6605C31.9801 34.5031 32.2208 34.2242 32.3481 33.94C32.5017 33.597 32.5017 33.1969 32.5017 32.3966V27.7011C32.5017 27.0282 32.5017 26.6917 32.3988 26.4033C32.308 26.1485 32.1604 25.9216 31.9691 25.7426C31.7526 25.54 31.459 25.425 30.8717 25.195L25.9087 23.2515C25.2255 22.9839 24.8839 22.8501 24.5598 22.8729C24.274 22.8929 23.9989 22.9979 23.7653 23.1763C23.5003 23.3786 23.3133 23.7142 22.9392 24.3856L21.6683 26.6667C17.5674 24.6665 14.2427 21.0815 12.3826 16.6667L14.5007 15.298C15.1242 14.8952 15.4359 14.6938 15.6237 14.4084C15.7893 14.1568 15.8869 13.8606 15.9055 13.5528C15.9265 13.2038 15.8023 12.8359 15.5539 12.1001L15.5539 12.1001L13.7492 6.75535C13.5356 6.12294 13.4288 5.80672 13.2407 5.57349C13.0744 5.36748 12.8638 5.20858 12.6272 5.11075C12.3593 5 12.0469 5 11.422 5H7.06193C6.31887 5 5.94733 5 5.62878 5.16542C5.36495 5.30243 5.10593 5.56169 4.95978 5.83505C4.78332 6.16511 4.75867 6.5277 4.70936 7.25288C4.66639 7.88476 4.64453 8.52297 4.64453 9.16667Z" stroke="#00B094" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                </a>
                                {/* <a className="" href={"mailto:" + data.email}> */}
                                <a className="" href={"mailto:" + "admin@seleckt.com"}>
                                    <svg width="27" height="27" viewBox="0 0 38 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M30.1705 30C30.7639 28.7499 31.0986 27.3337 31.0986 25.8333C31.0986 20.7707 27.2877 16.6667 22.5867 16.6667C17.8857 16.6667 14.0748 20.7707 14.0748 25.8333C14.0748 30.8959 17.8857 35 22.5867 35L32.6462 35C32.6462 35 31.0986 33.3333 30.1922 30.0486M29.3188 20C29.471 19.1922 29.551 18.3562 29.551 17.5C29.551 10.5964 24.3543 5 17.9438 5C11.5334 5 6.33668 10.5964 6.33668 17.5C6.33668 18.961 6.56942 20.3634 6.99715 21.6667C8.65963 26.6862 4.78906 30 4.78906 30H14.8486" stroke="#20127A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div className="media">
                            <div className="media-body text-nowrap">
                                <div className="text-black font-w600 fs-16 mb-0">
                                    {(() => {
                                        if (data.admin_input === false) {
                                            return (
                                                <button
                                                    className='btn btn-rounded btn-sm btn-light'
                                                    // onClick={() => {
                                                    //     dispatch(toggleAdminInput({ user_id: data._id, pageno: pageNumber === 0 ? 1 : pageNumber }));
                                                    // }}
                                                >
                                                    <span className="text-danger">Disabled</span>
                                                </button>
                                            )
                                        } else if (data.admin_input === true) {
                                            return (
                                                <button
                                                    className='btn btn-rounded btn-sm btn-light'
                                                    // onClick={() => {
                                                    //     dispatch(toggleAdminInput({ user_id: data._id, pageno: pageNumber === 0 ? 1 : pageNumber }));
                                                    // }}
                                                >
                                                    <span className="text-success">Enabled</span>
                                                </button>
                                            )
                                        }
                                        else {
                                            return (
                                                <button 
                                                className='btn btn-rounded btn-sm btn-light' 
                                                // onClick={() => {
                                                //     dispatch(toggleAdminInput({ user_id: data._id, pageno: pageNumber === 0 ? 1 : pageNumber }));
                                                // }}
                                                >
                                                    <span style={{ color: '#67088a' }}>Pending</span>
                                                     </button>
                                            )

                                        }

                                    })()}
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const changePage = async (data) => {
        setPageNumber(data.selected);
    };

    const templateSummary = (
        <Stack gap={3}>
            <div className='border-bottom'>
                <h6 className="mb-2 text-black">ID:</h6>
                {/* <p className="text-capitalize mb-3">{templates?.venue?.title}</p> */}
                <p className="mb-3 text-black">74326</p>
            </div>

            <div className='border-bottom'>
                <h6 className="mb-2 text-black">Company Name:</h6>
                <p className="mb-3 text-black">Glowingsoft Technologies</p>
                {/* <Button
                    type="button"
                    className="btn-sm mb-3"
                    style={{ borderRadius: "1.3rem" }}
                    variant="success"
                >
                    {templates?.industry?.title}
                </Button> */}
            </div>
            <div className='border-bottom'>
                <h6 className="mb-2 text-black">Company Address:</h6>
                <p className="mb-3 text-black">Office no 161, DHA Phase 1, lahore, pakistan</p>
            </div>

            <div className='border-bottom'>
                <h6 className=" mb-1 text-black">Company Logo:</h6>
                <img src={companyLogo} alt="" width="50" className="rounded-circle mb-3" />
            </div>

            <div className='border-bottom'>
                <h6 className=" mb-2 text-black">Industry:</h6>
                <div className='d-flex flex-wrap gap-1'>
                    <Button
                        type="button"
                        className="btn-sm mb-3 rounded-3"
                        variant="success"
                    >
                        Social Care
                    </Button>
                </div>
            </div>

            <div className='border-bottom'>
                <h6 className=" mb-2 text-black">Job Role:</h6>
                <p className="mb-3 text-black">Bartender</p>
            </div>
            <div className='border-bottom'>
                <h6 className=" mb-2 text-black">Rate P/H</h6>
                <p className="mb-3 text-black">24</p>
            </div>
            <div className='border-bottom'>
                <h6 className=" mb-2 text-black">Start Date and Time:</h6>
                <p className="mb-3 text-black">April 19th 2024 <br /> 02:21:39 PM</p>
            </div>
            <div className='border-bottom'>
                <h6 className=" mb-2 text-black">End Date and Time:</h6>
                <p className="mb-3 text-black">April 19th 2024 <br /> 02:21:39 PM</p>
            </div>
            <div className='border-bottom'>
                <h6 className=" mb-2 text-black">Total Hours:</h6>
                <p className="mb-3 text-black">24</p>
            </div>

            <div className='border-bottom'>
                <h6 className=" mb-1 text-black">Worker Picture:</h6>
                <img src={companyLogo} alt="" width="50" className="rounded-circle mb-3" />
            </div>
            <div className='border-bottom'>
                <h6 className=" mb-2 text-black">Worker Name:</h6>
                <p className="mb-3 text-black">Alex Hales</p>
            </div>
            <div className='border-bottom'>
                <h6 className=" mb-2 text-black">Daily Pay:</h6>
                <p className="mb-3">£24.00</p>
            </div>

            <div className='border-bottom'>
                <h6 className=" mb-2 text-black">Status:</h6>
                <div className='d-flex flex-wrap gap-1'>
                    <Button
                        type="button"
                        className="btn-sm mb-3"
                        style={{ borderRadius: "1.3rem" }}
                        variant="info"
                    >
                        Pending
                    </Button>
                </div>
            </div>

            <div className='border-bottom'>
                <h6 className=" mb-2 text-black">Total Amount:</h6>
                <p>£200.00</p>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <h6 className=" mb-2 text-black">Total Shifts Hrs:</h6>
                    <p>90 Hrs</p>
                </div>
                <div className="col-lg-6">
                    <h6 className="mb-2 text-black">Total Check-in/out Hrs</h6>
                    <p>102 Hrs</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <h6 className=" mb-2 text-black">Approval Date:</h6>
                    <p>April 19th 2024</p>
                </div>
                <div className="col-lg-6">
                    <h6 className=" mb-2 text-black">Actual Approved Hrs</h6>
                    <p>72 Hrs</p>
                </div>
            </div>
        </Stack>
    )


    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <span>
                    <Nav />
                    <div className="content-body">
                        <div className="container-fluid">
                            <div className="h-80">
                                <div className="d-flex justify-content-between ">
                                    <div className="mb-2">
                                        <h1 className="text-black fs-35 font-w600 mb-1">
                                            Appointments
                                        </h1>
                                        <h6>
                                            {userPerPage} Rows per page
                                        </h6>
                                    </div>
                                    <div className="col-lg-4 col-sm-12 mb-2 ">
                                        <Form className="d-flex flex-wrap gap-2 w-100 justify-content-end">
                                            <Dropdown
                                                // onSelect={(key) => handleSelectOption(key, { target: { getAttribute: () => 'Status' } })}
                                                className="flex-grow-1" style={{ maxWidth: '150px' }}
                                            >
                                                <Dropdown.Toggle id="dropdown-basic" className="py-3 rounded-4 border-dark  w-100 custom-dropdown-toggle bg-transparent text-success">
                                                    {/* <span className="mx-3" style={{ color: '#6C757D' }}>{selectedStatus}</span> */}
                                                    <span className="mx-3" style={{ color: '#6C757D' }}>All</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey="All">All</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                                                    <Dropdown.Item eventKey="on-boarded">On Boarded</Dropdown.Item>
                                                    <Dropdown.Item eventKey="declined">Declined</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <Dropdown
                                                //  onSelect={(key) => handleSelectOption(key, { target: { getAttribute: () => 'Approval' } })} 
                                                className="flex-grow-1" style={{ maxWidth: '170px' }}
                                            >
                                                <Dropdown.Toggle id="dropdown-basic" className="py-3 rounded-4 border-dark  w-100 custom-dropdown-toggle bg-transparent text-success">
                                                    {/* <span className="mx-3" style={{ color: '#6C757D' }}>{selectedApproval}</span> */}
                                                    <span className="mx-3" style={{ color: '#6C757D' }}>Newest</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey="Approved">Approved</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Cancel">Cancel</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="table-responsive">
                                            <div
                                                id="example5_wrapper"
                                                className="dataTables_wrapper no-footer"
                                            >
                                                <table
                                                    className="table display mb-4 dataTablesCard card-table dataTable no-footer"
                                                    id="example5"
                                                    role="grid"
                                                    aria-describedby="example5_info"
                                                >
                                                    <thead>
                                                        <tr role="row">
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="ID: activate to sort column ascending"

                                                            >
                                                                Id
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Company: activate to sort column ascending"

                                                            >
                                                                Name
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Company: activate to sort column ascending"

                                                            >
                                                                Email
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Contact: activate to sort column ascending"

                                                            >
                                                                Appointment
                                                            </th>

                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Date Applied: activate to sort column ascending"

                                                            >
                                                                Position
                                                            </th>

                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Contact: activate to sort column ascending"

                                                            >
                                                                Contact
                                                            </th>

                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Contact: activate to sort column ascending"

                                                            >
                                                                Status
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>{getTemlpates()}</tbody>
                                                </table>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <div
                                                        className="dataTables_info"
                                                        id="example5_info"
                                                        role="status"
                                                        aria-live="polite"
                                                    >
                                                        Showing {pageVisted + 1}
                                                        of {total_pages} pages
                                                    </div>
                                                    <div
                                                        className="dataTables_paginate paging_simple_numbers"
                                                        id="example5_paginate"
                                                    >
                                                        <ReactPaginate
                                                            previousLabel={"Previous"}
                                                            nextLabel={"Next"}
                                                            pageCount={pageCount}
                                                            onPageChange={changePage}
                                                            containerClassName={"paginationBttns"}
                                                            previousLinkClassName={
                                                                "paginate_button previous previousBttn"
                                                            }
                                                            nextLinkClassName={
                                                                "paginate_button next nextBttn"
                                                            }
                                                            pageLinkClassName={"paginate_button mr-1 ml-1"}
                                                            disabledClassName={"paginationDisabled"}
                                                            activeClassName={"paginationActive"}
                                                            forcePage={pageNumber}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
            )}
            <CustomeModal
                title="Timesheet"
                show={isModalOpen}
                onHide={handleCloseModal}
                content={templates ? templateSummary : "Loading..."}
            />
        </>
    );
}

export default Appointments;
