import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import uniformsService from "../uniforms/uniformsService";
import { getToken } from "../../utils/getToken";
import { editIndustry } from "../industries/industriesSlice";

const initialState = {
  uniforms: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: null,
  buttonLoading: false,
};

// Get All Uniforms
export const alluniforms = createAsyncThunk(
  "admin/uniforms",
  async (data, thunkAPI) => {
    const token = getToken();
    try {
      if (token) {
        return await uniformsService.getAllUniforms(token, data);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Uniform Toggle Status
export const toggleStatus = createAsyncThunk(
  "admin/uniform/status",
  async (data, thunkAPI) => {
    const { uniform_id, pageno } = data;
    const token = getToken();
    try {
      if (token) {
        return await uniformsService.toggleStatus(token, uniform_id, pageno);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//  Create Uniform
export const createUniform = createAsyncThunk(
  "admin/uniform",
  async (data, thunkAPI) => {
    const token = getToken();
    const { jobrole, pageno, description, icon ,industry_id } = data;
    const createObj = {
      jobrole_id: jobrole,
      description,
      image: icon,
      industry_id:industry_id
    };
    try {
      if (token) {
        return await uniformsService.createUniform(token, createObj, pageno);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message, "message in uniform slice");
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editUniform = createAsyncThunk(
  "admin/uniform/edit",
  async (data, thunkAPI) => {
    const token = getToken();
  
    const { editjobrole, pageno, editDescription, editIcon ,industry_id  ,uniform_id} = data;
    const createObj = {
      jobrole_id: editjobrole,
      description: editDescription,
      image: editIcon,
      industry_id:industry_id,
      uniform_id:uniform_id
    };
    try {
      if (token) {
        return await uniformsService.editUniform(token, createObj, pageno);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const uniformsSlice = createSlice({
  name: "unifrom",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(alluniforms.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(alluniforms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = null;
        state.uniforms = action.payload;
      })
      .addCase(alluniforms.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = null;
        state.uniforms = null;
        state.isSuccess = false;
      })
      .addCase(toggleStatus.pending, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(toggleStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.uniforms = action.payload;
      })
      .addCase(toggleStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
        state.isSuccess = false;
      })
      .addCase(createUniform.pending, (state) => {
        state.isLoading = false;
        state.buttonLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(createUniform.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.uniforms = action.payload;
        state.buttonLoading = false;
      })
      .addCase(createUniform.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.buttonLoading = false;
        state.isSuccess = false;
      })
      .addCase(editUniform.pending, (state) => {
        state.isLoading = false;
        state.buttonLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(editUniform.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.uniforms = action.payload;
        state.buttonLoading = false;
      })
      .addCase(editUniform.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.buttonLoading = false;
        state.isSuccess = false;
      })
  },
});

export const { reset } = uniformsSlice.actions;

export default uniformsSlice.reducer;