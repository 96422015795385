import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  allworkers,
  toggleApproved,
  toggleStatus,
  reset,
} from "../../../features/workers/workerSlice";
import Nav from "../../layouts/nav";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import Spinner from "../../components/Spinner";
import { Button, Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
// import StarRatings from './react-star-ratings';
import StartRatings from "react-star-ratings";
import App from "./Pagination";
import ReactDatePicker from "react-datepicker";
import noImage from "../../../images/noimage.png";
import { toast } from "react-toastify";
import { namespaces } from "chartist";

function Worker() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [startDatePicker, setStartDatePicker] = useState(null);
  const [dueDate, setDueDate] = useState(null);

  const [rating, setRating] = useState("Rating");
  const [selectedJoiningDate, setSelectedJoiningDate] =
    useState("Joining Date");
  const [selectedShift, setSelectedShift] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedApproval, setSelectedApproval] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);

  const [selectedRating, setSelectedRating] = useState({
    value: null,
    icon: null,
  });

  const handleSelectOption = (key, event) => {
    const ariaLabel = event.target.getAttribute("aria-label");

    switch (ariaLabel) {
      case "Shifts":
        setSelectedShift(key);
        break;
      case "Status":
        setSelectedStatus(key);
        break;
      case "Approval":
        setSelectedApproval(key);
        break;
      case "Rating":
        const rating = parseInt(key, 10);
        const icon = rating ? (
          <i className="fa-solid fa-star text-warning"></i>
        ) : null;
        setSelectedRating({ value: rating, icon });
        break;
      default:
        break;
    }
  };

  const handleSelect = (eventKey) => {
    setRating(eventKey);
  };

  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { workers, isLoading, isError, message  } = useSelector(
    (state) => state.workers
  );


  const getDate = (date) => {
    return dateFormat(date, "yyyy-mm-dd ");
  };

  // useEffect(() => {
  //   if (isError) {
  //     // toast.error(message);
  //   }
  //   if (!user) {
  //     navigate("/admin/login");
  //   }
  //   dispatch(allworkers(1));
  //   return () => reset();
  // }, [user, navigate, isError, message, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      if (isError) {
        toast.error(message);
      }
      if (!user) {
        navigate("/login");
      }
    }, 500);

    return () => dispatch(reset());
  }, [user, navigate, isError, message, dispatch]);

  const data = {
    pageno: 1,
    startDatePicker: startDatePicker,
    selectedShift: selectedShift,
    selectedStatus: selectedStatus,
    selectedApproval: selectedApproval,
    selectedRating: selectedRating.value,
  };

  useEffect(() => {
    dispatch(allworkers(data));
  }, [
    startDatePicker,
    selectedShift,
    selectedStatus,
    selectedApproval,
    selectedRating,
  ]);

 
  const userPerPage = 10;
  const pageVisted = pageNumber;

  let pageCount;
  const total_pages = workers?.total_pages;
  const workersData = workers?.body;

  const formatName = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    console.log(nameParts);
    if (nameParts.length > 1) {
      // return `${nameParts[0][0]}.${nameParts.slice(-1)}`
      return `${nameParts[0][0]}.${nameParts[1]}`;
    }
    return name;
  };

  const truncateString = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const getWorkr = () => {
    pageCount = total_pages;

    return (
      Array.isArray(workersData) &&
      workersData?.map((data, index) => {
        return (
          <tr role="row" className="odd" key={index}>
            <td>{index + 1 + (pageNumber === 0 ? 0 : pageNumber * 10)}.</td>
            <td>
              <div className="media">
                <div className="media-body text-nowrap gap-2">
                  <div className="">
                    <Link to={"/admin/worker/Profile/" + data._id}>
                      <div className="media-body text-nowrap btn p-0">
                        <img
                          src={data?.image}
                          alt={data?.name}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = noImage;
                          }}
                          height={50}
                          width={50}
                          className="rounded-circle"
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  {/* <h6
                    key={index}
                    className="fs-16 mb-0 text-capitalize"
                    title={formatName(data?.name)?.length > 10 ? formatName(data?.name) : ""}
                  >
                    {truncateString(formatName(data?.name), 10)}
                  </h6> */}
                   <OverlayTrigger
                      placement="top"
                      overlay={
                       <Tooltip>
                          {formatName(data?.name)?.length > 10 ? formatName(data?.name) : ""}
                      </Tooltip>
                      }
                      >
                     <h6
                        key={index} // Ensure unique key if rendering in a list
                        className="fs-16 mb-0 text-capitalize"
                     >
                          {truncateString(formatName(data?.name), 10)}
                    </h6>
                  </OverlayTrigger>
                </div>
              </div>
            </td>
            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  <div className="fs-16 mb-0 ">
                    {data.status === true ? (
                      <button className="btn btn-rounded btn-sm btn-light border-0">
                        <span
                          onClick={() => {
                            dispatch(
                              toggleStatus({
                                user_id: data._id,
                                pageno: pageNumber === 0 ? 1 : pageNumber,
                              })
                            );
                          }}
                          className="text-success"
                        >
                          Active
                        </span>
                      </button>
                    ) : (
                      <button className="btn btn-rounded btn-sm btn-light border-0">
                        <span
                          onClick={() => {
                            dispatch(
                              toggleStatus({
                                user_id: data._id,
                                pageno: pageNumber === 0 ? 1 : pageNumber,
                              })
                            );
                          }}
                          className="text-danger"
                        >
                          Inactive
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  <div className="fs-16 mb-0">
                    {(() => {
                      if (data.is_approved === "0") {
                        return (
                          <button className="btn btn-rounded btn-light btn-sm">
                            <span
                              onClick={() => {
                                dispatch(
                                  toggleApproved({
                                    user_id: data._id,
                                    pageno: pageNumber === 0 ? 1 : pageNumber,
                                  })
                                );
                              }}
                              style={{ color: "orange" }}
                            >
                              Pending
                            </span>
                          </button>
                        );
                      } else if (data.is_approved === "1") {
                        return (
                          <button className="btn btn-rounded btn-sm btn-light">
                            <span
                              onClick={() => {
                                dispatch(
                                  toggleApproved({
                                    user_id: data._id,
                                    pageno: pageNumber === 0 ? 1 : pageNumber,
                                  })
                                );
                              }}
                              className="text-success"
                            >
                              Approved
                            </span>
                          </button>
                        );
                      } else {
                        return (
                          <button className="btn btn-rounded btn-light btn-sm">
                            <span
                              onClick={() => {
                                dispatch(
                                  toggleApproved({
                                    user_id: data._id,
                                    pageno: pageNumber === 0 ? 1 : pageNumber,
                                  })
                                );
                              }}
                              className="text-danger"
                            >
                              Declined
                            </span>
                          </button>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div className="d-flex">
                <a className=" mr-1" href={"tel:" + data.mobile}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 38 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.64453 9.16667C4.64453 23.434 15.3844 35 28.6326 35C29.2303 35 29.823 34.9765 30.4097 34.9302C31.0831 34.8771 31.4198 34.8505 31.7263 34.6605C31.9801 34.5031 32.2208 34.2242 32.3481 33.94C32.5017 33.597 32.5017 33.1969 32.5017 32.3966V27.7011C32.5017 27.0282 32.5017 26.6917 32.3988 26.4033C32.308 26.1485 32.1604 25.9216 31.9691 25.7426C31.7526 25.54 31.459 25.425 30.8717 25.195L25.9087 23.2515C25.2255 22.9839 24.8839 22.8501 24.5598 22.8729C24.274 22.8929 23.9989 22.9979 23.7653 23.1763C23.5003 23.3786 23.3133 23.7142 22.9392 24.3856L21.6683 26.6667C17.5674 24.6665 14.2427 21.0815 12.3826 16.6667L14.5007 15.298C15.1242 14.8952 15.4359 14.6938 15.6237 14.4084C15.7893 14.1568 15.8869 13.8606 15.9055 13.5528C15.9265 13.2038 15.8023 12.8359 15.5539 12.1001L15.5539 12.1001L13.7492 6.75535C13.5356 6.12294 13.4288 5.80672 13.2407 5.57349C13.0744 5.36748 12.8638 5.20858 12.6272 5.11075C12.3593 5 12.0469 5 11.422 5H7.06193C6.31887 5 5.94733 5 5.62878 5.16542C5.36495 5.30243 5.10593 5.56169 4.95978 5.83505C4.78332 6.16511 4.75867 6.5277 4.70936 7.25288C4.66639 7.88476 4.64453 8.52297 4.64453 9.16667Z"
                      stroke="#00B094"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <a className="" href={"mailto:" + data.email}>
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 38 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30.1705 30C30.7639 28.7499 31.0986 27.3337 31.0986 25.8333C31.0986 20.7707 27.2877 16.6667 22.5867 16.6667C17.8857 16.6667 14.0748 20.7707 14.0748 25.8333C14.0748 30.8959 17.8857 35 22.5867 35L32.6462 35C32.6462 35 31.0986 33.3333 30.1922 30.0486M29.3188 20C29.471 19.1922 29.551 18.3562 29.551 17.5C29.551 10.5964 24.3543 5 17.9438 5C11.5334 5 6.33668 10.5964 6.33668 17.5C6.33668 18.961 6.56942 20.3634 6.99715 21.6667C8.65963 26.6862 4.78906 30 4.78906 30H14.8486"
                      stroke="#20127A"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  {/* <i className="fa-regular fa-comments fa-lg mx-2" style={{ color: '#20127A' }} /> */}
                </a>
              </div>
            </td>
            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  <h6 className=" fs-16 mb-0">{getDate(data.createdAt)}</h6>
                </div>
              </div>
            </td>
            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  <h6 className=" fs-16 mb-0">{data.completedShifts}</h6>
                </div>
              </div>
            </td>
            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  <h6 className=" fs-16 mb-0">2</h6>
                </div>
              </div>
            </td>
            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  <h6 className=" fs-16 mb-0 mx-2">
                    <svg
                      width="18"
                      height="20"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.49909 1.99224C8.81413 1.83592 9.18413 1.83592 9.49917 1.99224C9.7771 2.13014 9.92205 2.37247 9.99165 2.4972C10.0669 2.63211 10.144 2.80516 10.2211 2.97809C10.2248 2.9864 10.2285 2.99471 10.2322 3.00301L11.58 6.02669L14.8993 6.37702C15.0876 6.39686 15.276 6.41671 15.4275 6.44661C15.5677 6.47426 15.8429 6.53724 16.06 6.75895C16.306 7.01026 16.4203 7.36216 16.369 7.71008C16.3238 8.01702 16.1381 8.22976 16.041 8.3345C15.9359 8.44777 15.7952 8.57456 15.6545 8.70127L13.175 10.9358L13.8676 14.2009C13.9069 14.3861 13.9462 14.5714 13.9646 14.7248C13.9816 14.8666 14.0068 15.1478 13.863 15.4228C13.7 15.7344 13.4007 15.9519 13.0539 16.0106C12.748 16.0624 12.4883 15.9516 12.3587 15.8916C12.2185 15.8267 12.0544 15.732 11.8905 15.6374L8.99913 13.9697L6.10778 15.6374C5.94381 15.732 5.77974 15.8267 5.63956 15.8916C5.50993 15.9516 5.25023 16.0624 4.94433 16.0106C4.59758 15.9519 4.29824 15.7344 4.13525 15.4228C3.99146 15.1478 4.01662 14.8666 4.03363 14.7248C4.05203 14.5714 4.09137 14.3861 4.13068 14.2009L4.82322 10.9358L2.36403 8.71955C2.35727 8.71346 2.35051 8.70737 2.34375 8.70128C2.2031 8.57457 2.06235 8.44777 1.95731 8.3345C1.86018 8.22976 1.67451 8.01702 1.62924 7.71008C1.57792 7.36216 1.69226 7.01026 1.93828 6.75895C2.15532 6.53724 2.43058 6.47426 2.57072 6.44661C2.72228 6.41671 2.91067 6.39686 3.09894 6.37702C3.10799 6.37607 3.11705 6.37512 3.1261 6.37416L6.41828 6.02669L7.76608 3.00301C7.76979 2.9947 7.77349 2.98639 7.7772 2.97807C7.85424 2.80515 7.93134 2.63211 8.00661 2.4972C8.07621 2.37247 8.22116 2.13014 8.49909 1.99224Z"
                        fill="#FFD600"
                      />
                    </svg>

                    {/* <StartRatings
                  rating={Number(data?.rating)}
                  starRatedColor="yellow"
                  name="rating"
                  starDimension="20px"
                  starSpacing="0px"
                /> */}
                  </h6>
                </div>
              </div>
            </td>
            {/* <td>
            <div className="media">
              <div className="media-body text-nowrap">
               
              </div>
            </div>
          </td> */}
            <td>
              <div className="mx-2">
                <Link to={"/admin/worker/Profile/" + data._id}>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 38 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.5728 23.3332C20.2823 23.3332 21.668 21.8408 21.668 19.9998C21.668 18.1589 20.2823 16.6665 18.5728 16.6665C16.8633 16.6665 15.4776 18.1589 15.4776 19.9998C15.4776 21.8408 16.8633 23.3332 18.5728 23.3332Z"
                      fill="#00B094"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.32893 19.5004C4.49779 12.0639 10.9481 6.6665 18.5731 6.6665C26.1981 6.6665 32.6485 12.0639 34.8173 19.5005C34.9121 19.8256 34.9121 20.1742 34.8173 20.4993C32.6484 27.9358 26.1981 33.3332 18.5731 33.3332C10.9481 33.3332 4.49775 27.9357 2.32892 20.4992C2.23411 20.1741 2.23411 19.8255 2.32893 19.5004ZM12.3823 19.9998C12.3823 16.3179 15.1539 13.3332 18.5728 13.3332C21.9917 13.3332 24.7633 16.3179 24.7633 19.9998C24.7633 23.6817 21.9917 26.6665 18.5728 26.6665C15.1539 26.6665 12.3823 23.6817 12.3823 19.9998Z"
                      fill="#00B094"
                    />
                  </svg>
                </Link>
              </div>
            </td>
          </tr>
        );
      })
    );
  };

  const changePage = async (data) => {
    const newData = {
      pageno: data.selected + 1,
    };
    dispatch(allworkers(newData));
    setPageNumber(data.selected + 1);
  };

  const dropdownOptions = [
    { key: "most", label: "Most Complete Shifts" },
    { key: "less", label: "Less Complete Shifts" },
  ];

  const statusDropDownOptions = [
    { key: "active", label: "Active" },
    { key: "inactive", label: "Inactive" },
  ];

  const approvedDropdownOption = [
    { key: "0", label: "Pending" },
    { key: "1", label: "Approved" },
    { key: "2", label: "Declined" },
  ];

  const labelShifts = dropdownOptions?.find(
    (item) => item.key === selectedShift
  )?.label;

  const labelStatus = statusDropDownOptions?.find(
    (item) => item.key === selectedStatus
  )?.label;

  const labelApproved = approvedDropdownOption?.find(
    (item) => item.key === selectedApproval
  )?.label;

  const handleClearFilters = () => {
    setSelectedShift(null);
    setSelectedStatus(null);
    setSelectedApproval(null);
    setSelectedRating({ value: null, icon: null });
    setStartDatePicker(null);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <span>
          <Nav />
          <div className="content-body">
            <div className="container-fluid">
              <div className="h-80">
                <div className="row mb-4">
                  <div className="col-lg-2 mb-2">
                    <h1 className="text-black fs-35 font-w600">Workers</h1>
                    <h6 className="fs-16 mb-1">
                      {(pageVisted + userPerPage > total_pages
                        ? total_pages
                        : pageVisted + userPerPage) - pageVisted}
                      Rows per page
                    </h6>
                  </div>
                  <div className="col-lg-10 col-sm-12 mb-2">
                    <Form className="d-flex flex-wrap gap-2 w-100 justify-content-end">
                      <div className="date-picker-container position-relative">
                        {/* <div className="placeholder-text">Pending Invoice Date</div> */}
                        <ReactDatePicker
                          selected={startDatePicker}
                          onChange={(date) => setStartDatePicker(date)}
                          style={{ maxWidth: "200px", color: "#6C757D" }}
                          className="form-control rounded-4 border-dark w-100 custom-dropdown-toggle bg-transparent py-4 float-end"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Joining Date"
                          isClearable
                        />
                        <i
                          className="fa-solid fa-chevron-down fa-sm position-absolute calendar-icon-worker"
                          style={{ color: "#00B094" }}
                        />
                      </div>
                      <Dropdown
                        onSelect={(key) =>
                          handleSelectOption(key, {
                            target: { getAttribute: () => "Shifts" },
                          })
                        }
                        className="flex-grow-1"
                        style={{ maxWidth: "260px" }}
                      >
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="py-3 rounded-4 border-dark  w-100 custom-dropdown-toggle bg-transparent text-success"
                        >
                          <span className="mx-1" style={{ color: "#6C757D" }}>
                            {/* {selectedShift} */}
                            {labelShifts ? labelShifts : "Select Shifts"}
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {dropdownOptions.map((option) => (
                            <Dropdown.Item
                              key={option.key}
                              eventKey={option.key}
                            >
                              {option.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>

                      <Dropdown
                        onSelect={(key) =>
                          handleSelectOption(key, {
                            target: { getAttribute: () => "Status" },
                          })
                        }
                        className="flex-grow-1"
                        style={{ maxWidth: "150px" }}
                      >
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="py-3 rounded-4 border-dark  w-100 custom-dropdown-toggle bg-transparent text-success"
                        >
                          <span className="mx-1" style={{ color: "#6C757D" }}>
                            {labelStatus ? labelStatus : "Select Status"}
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {statusDropDownOptions?.map((option) => (
                            <Dropdown.Item
                              key={option.key}
                              eventKey={option.key}
                            >
                              {option.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>

                      <Dropdown
                        onSelect={(key) =>
                          handleSelectOption(key, {
                            target: { getAttribute: () => "Approval" },
                          })
                        }
                        className="flex-grow-1"
                        style={{ maxWidth: "150px" }}
                      >
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="py-3 rounded-4 border-dark  w-100 custom-dropdown-toggle bg-transparent text-success"
                        >
                          <span className="mx-1" style={{ color: "#626262" }}>
                            {labelApproved ? labelApproved : "Approval"}
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {approvedDropdownOption?.map((option) => (
                            <Dropdown.Item
                              key={option.key}
                              eventKey={option.key}
                            >
                              {option.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>

                      <Dropdown
                        onSelect={handleSelectOption}
                        className="flex-grow-1"
                        style={{ maxWidth: "200px" }}
                      >
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="py-3 rounded-4 border-dark w-100 custom-dropdown-toggle bg-transparent text-success"
                        >
                          <span style={{ color: "#626262" }}>
                          {selectedRating.value
                              ? selectedRating.value
                              : "Select Rating"}
                          </span>
                          <span className="text-black mx-2">
                            {selectedRating.icon}
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            eventKey="Select Rating"
                            aria-label="Rating"
                            onClick={() =>
                              setSelectedRating({
                                value: "Select Rating",
                                icon: null,
                              })
                            }
                            disabled
                          >
                            Select Rating
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="5" aria-label="Rating">
                            5 <i className="fa-solid fa-star text-warning"></i>
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="4" aria-label="Rating">
                            4 <i className="fa-solid fa-star text-warning"></i>
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="3" aria-label="Rating">
                            3 <i className="fa-solid fa-star text-warning"></i>
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="2" aria-label="Rating">
                            2 <i className="fa-solid fa-star text-warning"></i>
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="1" aria-label="Rating">
                            1 <i className="fa-solid fa-star text-warning"></i>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <Link to="/admin/worker-all-jobs">
                        <Button
                          type="button"
                          className="btn btn-success btn-md rounded-5 mb-3 py-3"
                        >
                          See All Jobs
                        </Button>
                      </Link>
                    </Form>
                    <Link
                      to="#"
                      className="float-end text-success text-right mx-4 text-decoration-underline"
                      onClick={() => handleClearFilters(null)}
                    >
                      Clear All
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="table-responsive">
                      <div
                        id="example5_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          className="table display mb-4 dataTablesCard card-table dataTable no-footer"
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                        >
                          <thead className="bg-primary">
                            <tr role="row">
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="ID: activate to sort column ascending"
                              >
                                Id
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Company: activate to sort column ascending"
                              >
                                Picture
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Company: activate to sort column ascending"
                              >
                                Name
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Contact: activate to sort column ascending"
                                style={{ paddingLeft: "23px" }}
                              >
                                Status
                              </th>

                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Contact: activate to sort column ascending"
                              >
                                Verification
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Status: activate to sort column ascending"
                              >
                                Contact
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date Applied: activate to sort column ascending"
                              >
                                Joined Date
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date Applied: activate to sort column ascending"
                              >
                                Shifts
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date Applied: activate to sort column ascending"
                              >
                                Strikes
                              </th>

                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Contact: activate to sort column ascending"
                              >
                                Ratings
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Status: activate to sort column ascending"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>{getWorkr()}</tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-between pb-2">
                          <div
                            className="dataTables_info"
                            id="example5_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing {pageVisted + 1}
                            of {total_pages} pages
                          </div>
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="example5_paginate"
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"paginationBttns"}
                              previousLinkClassName={
                                "paginate_button previous previousBttn"
                              }
                              nextLinkClassName={
                                "paginate_button next nextBttn"
                              }
                              pageLinkClassName={"paginate_button mr-1 ml-1"}
                              disabledClassName={"paginationDisabled"}
                              activeClassName={"paginationActive"}
                              forcePage={pageNumber}
                            />
                          </div>
                        </div>

                        {/* <App 
                        totalPages={pageCount}
                        currentPage={pageVisted + 1}
                        onPageChange={changePage}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      )}
    </>
  );
}

export default Worker;
