import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Form, Stack } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { allWorkersJobs, reset, workerJobs } from '../../../features/workers/workerSlice'
import dateFormat from "dateformat";
import Spinner from '../../components/Spinner'
import CustomeModal from '../../components/customeModal/CustomeModal'
import Nav from "../../layouts/nav"
import nofound from "../../../images/loader/noJobsFound.png"
import workerImage from "../../../images/avatar/1.jpg"
import ReactDatePicker from 'react-datepicker'
import { allJobs, getJobDetails, getJobWorkerList } from '../../../features/jobs/jobslice'
import { format, isValid, parse } from 'date-fns'

const WorkerSeeAllJobs = () => {

    const dispatch = useDispatch();
    const { id } = useParams()
    // const { workerJob, isLoading, isError, message } = useSelector((state) => state?.workers)
    // const {jobs , isLoading , isError , message ,jobDetail ,isSuccess} = useSelector((state) => state?.jobs)
    const { jobs, isLoading, isError, message, jobDetails, isSuccess } = useSelector((state) => state?.workers)
    console.log("jobs", jobs)

    const [showPopup, setShowPopup] = useState(false)
    const [showPaymentDetails, setShowPaymentDetails] = useState(false)
    const [selectedJob, setSelectedJob] = useState(null)
    const [showJobDetailsModal, setshowJobDetailsModal] = useState(false)
    const [activeButton, setActiveButton] = useState("");
    const [status, setStatus] = useState("completed")
    const [pageNumber, setPageNumber] = useState(0);
    const userPerPage = 10;
    const pageVisted = pageNumber;

    const [startDatePicker, setStartDatePicker] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const start_date = startDatePicker ? dateFormat(startDatePicker, "dd/mm/yyyy") : undefined;
    const end_date = dueDate ? dateFormat(dueDate, "dd/mm/yyyy") : undefined;

    const job_summary = jobDetails?.body || {}



    const [selectedFrom, setSelectedFrom] = useState('From');
    const [selectedTo, setSelectedTo] = useState('To');
    const [selectedOffers, setSelectedOffers] = useState('Offers');
    const [selectedStatus  , setSelectedStatus] = useState(null)

    const handleSelectFrom = (eventKey) => {
        setSelectedFrom(eventKey);
    };

    const handleSelectTo = (eventKey) => {
        setSelectedTo(eventKey);
    };

    const handleSelectStatus = (eventKey) => {
        setSelectedStatus(eventKey);
    };
    let pageCount;
    const total_pages = jobs?.total_pages
    const jobsList = jobs?.body

    function handleShowJobDetailsModal(job) {
        setSelectedJob(job)
        setshowJobDetailsModal(true)
    }


    const getDate = (date) => {
        return dateFormat(date, "dd/mm/yyyy");
    };
     const convertDate = (date) => {  
        const parsedDate = parse(date, "dd/MM/yyyy HH:mm", new Date()); // Parse input
        if (!isValid(parsedDate)) {
            return "Invalid Date"; // Handle invalid date gracefully
        }
        return format(parsedDate, "dd/MM/yyyy hh:mm a");
       }
    const handleCloseJobDetailsModal = () => {
        setshowJobDetailsModal(false)
        setshowJobDetailsModal(null)
    }

    const handleShowMoreClick = () => setShowPaymentDetails(!showPaymentDetails)

    const handleShowPopup = () => setShowPopup(true)
    const handleClosePopup = () => setShowPopup(false)

    const popUpContent = (
        <Stack>
            <p className='text-black'>Please update the "Hospitality" date/time. Posting a job is not allowed for previous dates/within the next 30 minutes.</p>
        </Stack>
    )



    const jobSummary = () => {
        if (isLoading) {
            return <div>Loading...</div>;
        }
        return (
            <Stack gap={3}>
            <div className="border-bottom text-black">
                <div className='text-center'>
                    <img src={job_summary?.image} alt={workerImage} className='rounded-circle' width="100" />
                </div>
                <h6 className=" mb-2 text-black">Venue:</h6>
                <p className="text-capitalize mb-3 text-black">{job_summary?.venue?.address?.line1}</p>
            </div>

            <div className="border-bottom">
                <h6 className=" mb-2 text-black">Industry:</h6>
                <Button
                    type="button"
                    className="btn-sm mb-3 rounded-4"
                    variant="success"
                >                    
                {job_summary?.industry?.title}
                </Button>
            </div>

            <div className="border-bottom">
                <h6 className="mb-2 text-black">Job Roles:</h6>
                <Button
                    type="button"
                    className="btn-sm mb-3 rounded-4"
                    variant="success"
                >
                    {job_summary?.jobrole?.title}
                </Button>
            </div>

            <div className="border-bottom">
                <h6 className=" mb-2 text-black">Skills:</h6>
                <div className="d-flex flex-wrap gap-1">
                {job_summary?.j_skill?.length > 0 ? (
                    <>
                    {job_summary?.j_skill?.map((skill, idx) => (
                          <Button
                          type="button"
                          className="btn-sm mb-3 rounded-4"
                          variant="success"
                      >
                            {skill?.title}
                      </Button>
                    ))}
                    </>
                    ): "No Skills Added"}
                </div>
            </div>

            <div className="border-bottom">
                <div className='d-flex justify-content-between my-3'>
                    <h6 className="mb-2 text-black">Date & Time:</h6>
                    <p className=" text-right text-success">Show all</p>
                </div>
                {job_summary?.datetime?.datetime?.length > 0 ? (
                    <>
                    {
                      job_summary?.datetime?.datetime?.map((date, idx) => {
                        return(
                            <>
                            <div className="d-flex mt-3" key={idx}>
                                <p className="text-capitalize mb-0 fs-13"><i class="fa-regular fa-calendar text-black mx-1"></i> {convertDate(date?.start_date)}</p>
                                <p className="text-capitalize mb-0 fs-13 mx-2">-</p>
                                <p className="text-capitalize mb-0 fs-13">{convertDate(date?.end_date)}</p>

                            </div>
                            <div className="d-flex mb-3 mt-2">
                                <p className="text-capitalize mb-0 fs-13"><b>Break:</b> {job_summary?.break_time} <span className='text-lowercase'>mins</span>  </p>
                                <p className="text-capitalize mb-0 fs-13 mx-2"></p>
                                
                                {/* <p className="text-capitalize mb-0 fs-13 text-success">Paid / <span className='text-danger'>Unpaid</span></p> */}
                                <p className="text-capitalize mb-0 fs-13">
                                {job_summary.break_paid 
                                    ? 
                                    (
                                        <>
                                         <span className="text-success">Paid / </span> 
                                         <span>Unpaid</span>
                                        </>
                                    ) 
                                    :  
                                    (
                                        <>
                                         <span>Paid / </span> 
                                          <span className="text-danger">Unpaid</span>
                                        </>
                                    )
                                }
                                 </p>
                            </div>
                            </>
                        )
                      })  
                    }
                    </>
                ):"No Date Added"}
            </div>

            <div className="border-bottom">
                <h6 className="mb-2 text-black">Additional Details:</h6>
                <div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <h6 className="text-black mb-1">Rate p/h</h6>
                            <p>£ {job_summary?.rate}</p>
                        </div>
                        <div className='col-lg-6'>
                            <h6 className="text-black mb-1 text-nowrap">Health & Safety Instructions</h6>
                            <p>{job_summary?.health_safety === false ? ("Not Added"): (job_summary?.hs_description)}</p>
                        </div>
                    </div>

                    <div className='row'>

                        <div className='col-lg-6'>
                            <h6 className="text-black mb-1 text-nowrap">Break Times</h6>
                            <p>{job_summary?.break_time}</p>
                        </div>

                        <div className='col-lg-6'>
                            <h6 className="text-black text-nowrap">Break Paid</h6>
                            <p>{job_summary?.break_paid === false ? "unpaid": "paid"}</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <h6 className="text-black mb-1">Health & Safety Issues?</h6>
                        <p>{job_summary?.health_safety === false ? ("No"): ("Yes")}</p>
                    </div>

                    <div className='col-lg-6'>
                        <h6 className="text-black mb-1 text-nowrap">Job Description:</h6>
                        <p>{job_summary?.description}</p>
                    </div>
                </div>
            </div>

            <div className="border-bottom">
                <h6 className="text-black d-flex flex-column mb-2">Job Uniform:</h6>
                <div className="d-flex gap-3 align-items-center my-4">
                    <img src={job_summary?.uniform?.image} alt="img" height={50} width={50} className='rounded-circle' />
                    <div className='d-column'>
                        <p className='mb-0 text-black'>{job_summary?.uniform?.description} </p>
                        {/* <p>Test 123 lorme  </p> */}
                    </div>
                </div>
            </div>

            <div className="border-bottom">
                <h6 className="text-black d-flex flex-column mb-1">Uploads</h6>
                <p>{job_summary?.is_upload}</p>
            </div>

            <div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h6 className="text-black d-flex flex-column mb-0">Payment Details:</h6>
                    <p
                        className="text-right mb-0 c-pointer text-success"
                        style={{ color: "#2FE6DE" }}
                        onClick={handleShowMoreClick}
                    >
                        {showPaymentDetails ? "Show less" : "Show more"}
                    </p>
                </div>
                {showPaymentDetails && (
                    <div className="d-flex" style={{ gap: "11rem" }}>
                        <div>
                            <div>
                                <h6 className="text-black d-flex flex-column mb-2">Total Hrs</h6>
                                <p
                                    className="btn-md mb-3 px-5 py-2"
                                    style={{ borderRadius: "1.3rem", backgroundColor: "#edf2f4" }}
                                >
                                    {job_summary?.total_hours || 0.00}
                                </p>
                            </div>

                            <div>
                                <h6 className="text-black d-flex flex-column mb-2">VAT %</h6>
                                <p
                                    className="btn-md mb-3 px-5 py-2"
                                    style={{ borderRadius: "1.3rem", backgroundColor: "#edf2f4" }}
                                >
                                    {job_summary?.VAT || 0.00}
                                </p>
                            </div>
                        </div>

                        <div>
                            <div>
                                <h6 className="text-black d-flex flex-column mb-2">NI %</h6>
                                <p
                                    className="btn-md mb-3 px-5 py-2"
                                    style={{ borderRadius: "1.3rem", backgroundColor: "#edf2f4" }}
                                >
                                    {job_summary?.NI || 0.00}
                                </p>
                            </div>

                            <div>
                                <h6 className="text-black d-flex flex-column mb-2">Fee %</h6>
                                <p
                                    className="btn-md mb-3 px-5 py-2"
                                    style={{ borderRadius: "1.3rem", backgroundColor: "#edf2f4" }}
                                >
                                    {job_summary?.FEE || 0.00}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="d-flex gap-5 align-items-center mt-4">
                    <h6 className="text-black d-flex flex-column mb-0">Total Payment</h6>
                    {/* <i className="fa-solid fa-circle-info fs-5 c-pointer text-success" onClick={handleShowPopup}></i> */}
                </div>
                <p className=" text-black mb-5">£{job_summary?.total_payment}</p>
                {/* <PopUp title="Info" show={showPopup} onHide={handleClosePopup} content={popUpContent} /> */}
            </div>
        </Stack>
        )
    }


    useEffect(() => { setActiveButton(status) }, [status])

    const data = {
        pageno:1,
        status:selectedStatus,
        start_date:start_date,
        end_date:end_date
    }

    useEffect(() => {
        if (isError && !isSuccess) {
            toast.error(jobs?.message);
        }
        // dispatch(workerJobs({ id: id, status: status, pageno: 1 }));
        dispatch(allWorkersJobs(data))
        return () => dispatch(reset());
    }, [isError, message, dispatch, id, selectedStatus, startDatePicker, dueDate]);

    const handleStatusClick = (status) => {
        setStatus(status);
        setActiveButton(status)
        dispatch(workerJobs({ id: id, status: status, pageno: 1 }));
    };

    useEffect(()=>{
        if(selectedJob?._id){
            dispatch(getJobWorkerList({job_id: selectedJob?._id}))
        }
    },[selectedJob])

 

    const getWorkr = () => {

        const formatName = (name) => {
            if (!name) return '';
            const nameParts = name.split(' ');
            if (nameParts?.length > 1) {
                return `${nameParts[0][0]}.${nameParts[1]}`;
            }
            return name;
        };

        const truncateString = (text, maxLength) => {
            return text?.length > maxLength ? `${text?.slice(0, maxLength)}...` : text;
        };


        return jobsList?.map((job, idx) => {
            return (
                <tr key={job._id} role="row" className="odd">
                    {console.log("job now", job)}
                    <td>{idx + 1}.</td>
                    <td>
                        <div className="media">
                            <div className="media-body text-nowrap">
                                <h6
                                    key={idx}
                                    className="fs-16 mb-0 text-capitalize"
                                    title={job?.user?.name?.length > 10 ? job?.user?.name : ''}
                                >
                                    {truncateString(formatName(job?.user?.name), 10)}
                                </h6>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="media">
                            <div className="media-body text-nowrap">
                                <div className="text-nowrap">
                                    <h6 className="mb-0">{job?.industry?.title}</h6>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="media">
                            <div className="media-body text-nowrap">
                                <div className="text-nowrap">
                                    <h6 className="mb-0 text-capitalize">{job?.jobrole?.title}</h6>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="media">
                            <div className="media-body text-nowrap">
                                <div>
                                    <h6
                                        className=" fs-16 mb-0"
                                        title={job?.address?.address?.line1?.length > 20 ? job?.address?.address?.line1 : ''}
                                    >
                                        {truncateString(job?.address?.address?.line1, 20)}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="media">
                            <div className="media-body text-nowrap">
                                <h6 className="mb-0">{Array.isArray(job.job_datetime?.datetime) ? getDate(job.job_datetime?.datetime[0]?.start_date) :"Not Added"}</h6>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="media">
                            <div className="media-body text-nowrap">
                                <h6 className="mb-0">{Array.isArray(job.job_datetime?.datetime) ? getDate(job.job_datetime?.datetime[job.job_datetime?.datetime - 1]?.end_date?.createdAt) : "Not Added"}</h6>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="media">
                            <div className="media-body text-nowrap">
                                <h6 className="mb-0 text-capitalize">{job?.rate}</h6>
                            </div>
                        </div>
                    </td>
                    <td onClick={() => handleShowJobDetailsModal(job)} className='mx-2'>
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 38 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M18.5728 23.3332C20.2823 23.3332 21.668 21.8408 21.668 19.9998C21.668 18.1589 20.2823 16.6665 18.5728 16.6665C16.8633 16.6665 15.4776 18.1589 15.4776 19.9998C15.4776 21.8408 16.8633 23.3332 18.5728 23.3332Z"
                                fill="#00B094"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.32893 19.5004C4.49779 12.0639 10.9481 6.6665 18.5731 6.6665C26.1981 6.6665 32.6485 12.0639 34.8173 19.5005C34.9121 19.8256 34.9121 20.1742 34.8173 20.4993C32.6484 27.9358 26.1981 33.3332 18.5731 33.3332C10.9481 33.3332 4.49775 27.9357 2.32892 20.4992C2.23411 20.1741 2.23411 19.8255 2.32893 19.5004ZM12.3823 19.9998C12.3823 16.3179 15.1539 13.3332 18.5728 13.3332C21.9917 13.3332 24.7633 16.3179 24.7633 19.9998C24.7633 23.6817 21.9917 26.6665 18.5728 26.6665C15.1539 26.6665 12.3823 23.6817 12.3823 19.9998Z"
                                fill="#00B094"
                            />
                        </svg>
                    </td>
                </tr>
            );
        });
    };


    const changePage = async (data) => {
        dispatch(workerJobs(data.selected + 1))
        setPageNumber(data.selected);
    };

    const statusDropDownOptions = [
        { key: "offered", label: "Offers"},
        { key: "hired", label: "Hired" },
        { key:"ongoing", label:"Ongoing"},
        { key:"completed", label:"Completed"},
        { key:"not_hired", label:"Not Hired"},
        { key:"declined", label:"Declined"}
      ];

      
  const labelStatus = statusDropDownOptions?.find(
    (item) => item.key === selectedStatus
  )?.label;

    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <span>
                    <Nav />
                    <div className="content-body">
                        <div className="container-fluid">
                            <div className="h-80">
                                <div className="d-flex flex-wrap mb-4 row">
                                    <div className="col-xl-6 col-lg-4 mb-2">
                                        <h1 className="text-black fs-35 font-w600 ">
                                            Jobs
                                        </h1>
                                        <h6 className="fs-16 mb-1">
                                            {(pageVisted + userPerPage > total_pages
                                                ? total_pages
                                                : pageVisted + userPerPage) - pageVisted}
                                            {" "} Rows per page
                                        </h6>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 mb-2 ">
                                        <Form className="d-flex gap-2 justify-content-end">
                                            <div className="date-picker-container position-relative">
                                                <ReactDatePicker
                                                    selected={startDatePicker}
                                                    onChange={(date) => setStartDatePicker(date)}
                                                    style={{ maxWidth: '200px' }}
                                                    className="form-control rounded-4 border-dark w-100 custom-dropdown-toggle bg-transparent py-4 float-end"
                                                    dateFormat="dd/MM/yyyy"
                                                    placeholderText="From"
                                                    isClearable
                                                />
                                                <i className="fa-solid fa-chevron-down fa-sm position-absolute calendar-icon-worker mt-2" style={{ color: '#00B094' }} />
                                            </div>
                                            <div className="date-picker-container position-relative mr-3">
                                                <ReactDatePicker
                                                    selected={dueDate}
                                                    onChange={(date) => setDueDate(date)}
                                                    style={{ maxWidth: '200px' }}
                                                    className="form-control rounded-4 border-dark w-100 custom-dropdown-toggle bg-transparent py-4"
                                                    dateFormat="dd/MM/yyyy"
                                                    placeholderText="To"
                                                    isClearable
                                                />
                                                <i className="fa-solid fa-chevron-down fa-sm position-absolute calendar-icon-worker mt-2" style={{ color: '#00B094' }} />
                                            </div>
                                            <Dropdown onSelect={handleSelectStatus} className="flex-grow-1" style={{ maxWidth: '200px' }}>
                                                <Dropdown.Toggle id="dropdown-offers" className=" rounded-4 border-dark w-100 custom-dropdown-toggle bg-transparent text-success float-start py-3">
                                                    <span style={{ color: '#6C757D' }}>{labelStatus ? labelStatus :'Select Status'}</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                {statusDropDownOptions?.map((option) => (
                                                        <Dropdown.Item
                                                         key={option.key}
                                                         eventKey={option.key}
                                                         >
                                                        {option.label}
                                                        </Dropdown.Item>
                                                ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="table-responsive">
                                            <div
                                                id="example5_wrapper"
                                                className="dataTables_wrapper no-footer"
                                            >
                                                <table
                                                    className="table display mb-4 dataTablesCard card-table dataTable no-footer"
                                                    id="example5"
                                                    role="grid"
                                                    aria-describedby="example5_info"
                                                >
                                                    <thead>
                                                        <tr role="row">
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="ID: activate to sort column ascending"
                                                            >
                                                                Id
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Company: activate to sort column ascending"
                                                            >
                                                                Name
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Company: activate to sort column ascending"
                                                            >
                                                                Industry
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Type: activate to sort column ascending"
                                                            >
                                                                Job Roles
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Contact: activate to sort column ascending"
                                                            >
                                                                Address
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Status: activate to sort column ascending"
                                                            >
                                                                Start Date & Time
                                                            </th>


                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Date Applied: activate to sort column ascending"
                                                            >
                                                                End Date & Time
                                                            </th>

                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Status: activate to sort column ascending"
                                                            >
                                                                Rate/PH
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Status: activate to sort column ascending"
                                                            >
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            jobsList ? getWorkr() : (
                                                                <tr>
                                                                    <td colSpan="10" className="text-center text-red">
                                                                        <img src={nofound} alt={nofound} width="30%" height="30%" />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <div
                                                        className="dataTables_info"
                                                        id="example5_info"
                                                        role="status"
                                                        aria-live="polite"
                                                    >
                                                        Showing {pageVisted + 1}
                                                        of {total_pages} pages
                                                    </div>
                                                    <div
                                                        className="dataTables_paginate paging_simple_numbers"
                                                        id="example5_paginate"
                                                    >
                                                        <ReactPaginate
                                                            previousLabel={"Previous"}
                                                            nextLabel={"Next"}
                                                            pageCount={pageCount}
                                                            onPageChange={changePage}
                                                            containerClassName={"paginationBttns"}
                                                            previousLinkClassName={
                                                                "paginate_button previous previousBttn"
                                                            }
                                                            nextLinkClassName={
                                                                "paginate_button next nextBttn"
                                                            }
                                                            pageLinkClassName={"paginate_button mr-1 ml-1"}
                                                            disabledClassName={"paginationDisabled"}
                                                            activeClassName={"paginationActive"}
                                                            forcePage={"pageNumber"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </span >
            )}

            {/* Job modal */}
            <CustomeModal
                title="Details"
                show={showJobDetailsModal}
                onHide={handleCloseJobDetailsModal}
                content={(jobSummary(selectedJob))}
            />
        </>
    )
}

export default WorkerSeeAllJobs
