import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASEURL

const dashboard = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(`${BASE_URL}/admin/onboarding_staff/dashboard`, config);
    if (response.data.body) {
        return response.data;
    }
}




const homeService = {
    dashboard
}


export default homeService;