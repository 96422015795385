import React, { Component } from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import MetisMenu from "metismenujs";
import Logout from "../../pages/Logout";

class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }

   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class OnboardingAdminSideBar extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showLogoutModal: false,
      };
   }

   componentDidMount() {
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);
   }
   handleShowLogoutModal = (e) => {
      e.preventDefault(); // Prevent the default link behavior
      this.setState({ showLogoutModal: true });
   };

   handleCloseLogoutModal = () => {
      this.setState({ showLogoutModal: false });
   };

   handleLogout = () => {
      // Implement your logout logic here
      // For example, clear authentication tokens and redirect
      this.handleCloseLogoutModal();
      // Redirect to login or home page
      window.location.href = "/login"; // Or use history.push if you're using react-router
   };


   render() {
      const path = window.location.pathname;
      const { showLogoutModal } = this.state;

      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <MM className="metismenu" id="menu">
                  <li className={`${path.slice(1) === "onboarding/dashboard" ? "mm-active" : ""}`}>
                     <Link to="/onboarding/dashboard">
                        {/* <i className="flaticon-381-speedometer"></i> */}
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M6.66406 9.33317C6.66406 8.39975 6.66406 7.93304 6.84572 7.57652C7.00551 7.26292 7.26047 7.00795 7.57408 6.84816C7.9306 6.6665 8.39731 6.6665 9.33073 6.6665H13.9974C14.9308 6.6665 15.3975 6.6665 15.754 6.84816C16.0676 7.00795 16.3226 7.26292 16.4824 7.57652C16.6641 7.93304 16.6641 8.39975 16.6641 9.33317V17.3332C16.6641 18.2666 16.6641 18.7333 16.4824 19.0898C16.3226 19.4034 16.0676 19.6584 15.754 19.8182C15.3975 19.9998 14.9308 19.9998 13.9974 19.9998H9.33073C8.39731 19.9998 7.9306 19.9998 7.57408 19.8182C7.26047 19.6584 7.00551 19.4034 6.84572 19.0898C6.66406 18.7333 6.66406 18.2666 6.66406 17.3332V9.33317Z" stroke="white" stroke-opacity="0.8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                           <path d="M23.3307 22.6665C23.3307 21.7331 23.3307 21.2664 23.5124 20.9099C23.6722 20.5962 23.9271 20.3413 24.2407 20.1815C24.5973 19.9998 25.064 19.9998 25.9974 19.9998H30.6641C31.5975 19.9998 32.0642 19.9998 32.4207 20.1815C32.7343 20.3413 32.9893 20.5962 33.1491 20.9099C33.3307 21.2664 33.3307 21.7331 33.3307 22.6665V30.6665C33.3307 31.5999 33.3307 32.0666 33.1491 32.4232C32.9893 32.7368 32.7343 32.9917 32.4207 33.1515C32.0642 33.3332 31.5975 33.3332 30.6641 33.3332H25.9974C25.064 33.3332 24.5973 33.3332 24.2407 33.1515C23.9271 32.9917 23.6722 32.7368 23.5124 32.4232C23.3307 32.0666 23.3307 31.5999 23.3307 30.6665V22.6665Z" stroke="white" stroke-opacity="0.8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                           <path d="M6.66406 29.3332C6.66406 28.3997 6.66406 27.933 6.84572 27.5765C7.00551 27.2629 7.26047 27.0079 7.57408 26.8482C7.9306 26.6665 8.39731 26.6665 9.33073 26.6665H13.9974C14.9308 26.6665 15.3975 26.6665 15.754 26.8482C16.0676 27.0079 16.3226 27.2629 16.4824 27.5765C16.6641 27.933 16.6641 28.3997 16.6641 29.3332V30.6665C16.6641 31.5999 16.6641 32.0666 16.4824 32.4232C16.3226 32.7368 16.0676 32.9917 15.754 33.1515C15.3975 33.3332 14.9308 33.3332 13.9974 33.3332H9.33073C8.39731 33.3332 7.9306 33.3332 7.57408 33.1515C7.26047 32.9917 7.00551 32.7368 6.84572 32.4232C6.66406 32.0666 6.66406 31.5999 6.66406 30.6665V29.3332Z" stroke="white" stroke-opacity="0.8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                           <path d="M23.3307 9.33317C23.3307 8.39975 23.3307 7.93304 23.5124 7.57652C23.6722 7.26292 23.9271 7.00795 24.2407 6.84816C24.5973 6.6665 25.064 6.6665 25.9974 6.6665H30.6641C31.5975 6.6665 32.0642 6.6665 32.4207 6.84816C32.7343 7.00795 32.9893 7.26292 33.1491 7.57652C33.3307 7.93304 33.3307 8.39975 33.3307 9.33317V10.6665C33.3307 11.5999 33.3307 12.0666 33.1491 12.4232C32.9893 12.7368 32.7343 12.9917 32.4207 13.1515C32.0642 13.3332 31.5975 13.3332 30.6641 13.3332H25.9974C25.064 13.3332 24.5973 13.3332 24.2407 13.1515C23.9271 12.9917 23.6722 12.7368 23.5124 12.4232C23.3307 12.0666 23.3307 11.5999 23.3307 10.6665V9.33317Z" stroke="white" stroke-opacity="0.8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span className="nav-text mx-3">Dashboard</span>
                     </Link>
                  </li>

                  {/* <li className={`${path.slice(1) === "onboarding/appointments" ? "mm-active" : ""}`}>
                     <Link to="/onboarding/appointments">
                        <i className="flaticon-381-search"></i>
                        <span className="nav-text">Appointments</span>
                     </Link>
                  </li> */}

                  <li className={`${path.slice(1) === "onboarding/slots" ? "mm-active" : ""}`}>
                     <Link to="/onboarding/slots">
                        <i className="flaticon-381-search"></i>
                        <span className="nav-text">Onboarding</span>
                     </Link>
                  </li>

                  <li>
                     <a href="/admin/logout" onClick={this.handleShowLogoutModal}>
                        {/* <i className="flaticon-381-exit"></i> */}
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M20 35C11.7157 35 5 28.2843 5 20C5 11.7157 11.7157 5 20 5M28.3333 26.6667L35 20M35 20L28.3333 13.3333M35 20H15" stroke="white" stroke-opacity="0.8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                        <span className="nav-text mx-3">Logout</span>
                     </a>
                  </li>
               </MM>
               
               <Logout
                  show={showLogoutModal}
                  handleClose={this.handleCloseLogoutModal}
                  handleLogout={this.handleLogout}
               />

               <div className="copyright"></div>
            </PerfectScrollbar>
         </div>
      );
   }
}

export default OnboardingAdminSideBar;
