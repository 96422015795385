import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getToken } from '../../utils/getToken';
import onboardingAdminService from './onboardingAdminService';


const initialState = {
    adminUser: null,
    status: null,
    changePassword: null,
    createdAdminUser: null,
    slots: null,
    updateAppointmentStatus: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}

export const createOnboardingAdmin = createAsyncThunk('admin/onboarding_staff', async (user, thunkAPI) => {
    const token = getToken()

    try {
        return await onboardingAdminService.createOnboardingAdmin(token, user);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getAllOnboardingAdmins = createAsyncThunk('admin/getAllOnboardingAdmins', async ({ pageno }, thunkAPI) => {
    const token = getToken()
    try {
        return await onboardingAdminService.getOnboardingAdmins(token, pageno);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const toggleAdminStatus = createAsyncThunk(
    'admin/toggle-Admin-Status',
    async (userId, thunkAPI) => {
        const token = getToken();
        try {
            const response = await onboardingAdminService.toggleAdminStatus(token, userId.user_id);
            return response;
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const changeAdminPassword = createAsyncThunk('admin/change-password', async (userData, thunkAPI) => {
    const token = getToken();
    try {
        const response = await onboardingAdminService.changePassword(token, userData);
        return response;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
}
);

export const onboardingSlots = createAsyncThunk('admin/onboardingSlots', async (date, thunkAPI) => {
    const token = getToken();
    try {
        return await onboardingAdminService.onboardingSlots(token, date);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const updateAppointmentStatus = createAsyncThunk('admin/updateAppointmentStatus', async (appointment, thunkAPI) => {
    const token = getToken();
    try {
        return await onboardingAdminService.updateAppointmentStatus(token, appointment);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});



export const onboardingAdminSlice = createSlice({
    name: "onboardingAdmin",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createOnboardingAdmin.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(createOnboardingAdmin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.createdAdminUser = action.payload;
            })
            .addCase(createOnboardingAdmin.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
                state.createdAdminUser = null;
            })
            .addCase(getAllOnboardingAdmins.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
            })
            .addCase(getAllOnboardingAdmins.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.adminUser = action.payload;
            })
            .addCase(getAllOnboardingAdmins.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.adminUser = null;
            })
            .addCase(toggleAdminStatus.pending, (state) => {
                state.isLoading = false;
            })
            .addCase(toggleAdminStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                const adminIndex = state.adminUser.body.findIndex(
                    (admin) => admin._id === action.payload._id
                );
                if (adminIndex !== -1) {
                    state.adminUser.body[adminIndex].status = action.payload.status;
                }
            })
            .addCase(toggleAdminStatus.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload || 'Error occurred';
            })
            .addCase(changeAdminPassword.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(changeAdminPassword.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.changePassword = action.payload;
            })
            .addCase(changeAdminPassword.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.changePassword = null;
            })
            .addCase(onboardingSlots.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(onboardingSlots.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.slots = action.payload;
            })
            .addCase(onboardingSlots.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.slots = null;
            })
            .addCase(updateAppointmentStatus.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateAppointmentStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.updateAppointmentStatus = action.payload;
            })
            .addCase(updateAppointmentStatus.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.updateAppointmentStatus = null;
            })
    }
})
export const { reset } = onboardingAdminSlice.actions;


export default onboardingAdminSlice.reducer