import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Dropdown, Form, Stack } from "react-bootstrap";
import Nav from "../../layouts/nav";
import ReactPaginate from "react-paginate";
import Spinner from "../../components/Spinner";
import { createTimeSlot, getAllTimeSlots, updateTimeSlot } from "../../../features/timeslots/timeslotSlice";
import CustomeModal from "../../components/customeModal/CustomeModal";


function AppointmentSlots() {
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectTiemsoltTitle, setSelectTimeslotTitle] = useState('')
    const [selectTiemsoltStatus, setSelectTimeslotStatus] = useState('')
    const [isEditMode, setIsEditMode] = useState(false);
    const [editTimeslotId, setEditTimeslotId] = useState(null);

    const handleShowModal = (timeslot = null) => {
        if (timeslot) {
            setIsEditMode(true);
            setSelectTimeslotTitle(timeslot.title);
            setSelectTimeslotStatus(timeslot.is_active);
            setEditTimeslotId(timeslot._id);
        } else {
            setIsEditMode(false);
            setSelectTimeslotTitle("");
            setSelectTimeslotStatus("");
        }
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectTimeslotTitle("");
        setSelectTimeslotStatus("");
        setIsEditMode(false);
        setEditTimeslotId(null);
    };

    const handleSelect = (eventKey) => {
        setSelectTimeslotTitle(eventKey);
    };

    const handleStatusSelect = (eventKey) => {
        setSelectTimeslotStatus(eventKey);
    }

    const { allTimeSlots, isError, message, isLoading } = useSelector((state) => state?.timeslot);

    useEffect(() => {
        if (isError) {
            toast.error(message)
        }

        dispatch(getAllTimeSlots(1))
    }, [dispatch, isError, message]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isEditMode) {
            dispatch(updateTimeSlot({ id: editTimeslotId, is_active: selectTiemsoltStatus == 'active' ? true : false }));
            toast.success("Timeslot updated successfully!");
        } else {
            dispatch(createTimeSlot({ title: selectTiemsoltTitle }));
            toast.success("Timeslot added successfully!");
        }
        setIsModalOpen(false);
        dispatch(getAllTimeSlots(1));
    };

    const [pageNumber, setPageNumber] = useState(0);
    const userPerPage = 10;
    const pageVisted = pageNumber;
    let pageCount;
    const total_pages = allTimeSlots?.body?.total_pages

    const getTimeSlots = () => {

        pageCount = total_pages;
        return allTimeSlots?.body?.slots.map((timeslot, index) => {
            return (
                <tr role="row" className="odd" key={index}>
                    <td>
                        {index + 1 + pageVisted * userPerPage}
                    </td>
                    <td>
                        <div className="media">
                            <div className="media-body text-nowrap">
                                {timeslot.title}
                            </div>
                        </div>
                    </td>

                    <td>
                        <div className="media">
                            <div className="media-body text-nowrap">
                                <div className="text-black font-w600 fs-16 mb-0">
                                    {timeslot.is_active ? (
                                        <button className="btn btn-rounded btn-sm btn-light">
                                            <span className="text-success">Active</span>
                                        </button>
                                    ) : (
                                        <button className="btn btn-rounded btn-sm btn-light">
                                            <span className="text-danger">Inactive</span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <Button
                            variant="primary"
                            size="sm"
                            className="rounded-4"
                            onClick={() => handleShowModal(timeslot)}
                        >
                            Edit
                        </Button>
                    </td>
                </tr>
            );
        });
    };

    const changePage = async (data) => {
        dispatch(getAllTimeSlots(data.selected + 1));
        setPageNumber(data.selected);
    };

    const timeslotsContent = (
        <Stack gap={3}>
            <Form>
                <Dropdown className="flex-grow-1" style={{ maxWidth: '100%' }} onSelect={handleSelect}>
                    <Dropdown.Toggle
                        id="dropdown-basic"
                        className="py-3 rounded-4 border-dark w-100 custom-dropdown-toggle bg-transparent text-success d-flex align-items-center justify-content-between pl-1 mr-2"
                    >
                        <span className="mx-3" style={{ color: '#6C757D' }}>{selectTiemsoltTitle || "Select timeslot"}
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ width: '100%', maxHeight: '300px', overflowY: 'auto' }}>
                        <Dropdown.Item eventKey="09:00 - 09:30">09:00 - 09:30</Dropdown.Item>
                        <Dropdown.Item eventKey="09:30 - 10:00">09:30 - 10:00</Dropdown.Item>
                        <Dropdown.Item eventKey="10:00 - 10:30">10:00 - 10:30</Dropdown.Item>
                        <Dropdown.Item eventKey="10:30 - 11:00">10:30 - 11:00</Dropdown.Item>
                        <Dropdown.Item eventKey="11:00 - 11:30">11:00 - 11:30</Dropdown.Item>
                        <Dropdown.Item eventKey="11:30 - 12:00">11:30 - 12:00</Dropdown.Item>
                        <Dropdown.Item eventKey="12:00 - 12:30">12:00 - 12:30</Dropdown.Item>
                        <Dropdown.Item eventKey="12:30 - 13:00">12:30 - 13:00</Dropdown.Item>
                        <Dropdown.Item eventKey="13:00 - 13:30">13:00 - 13:30</Dropdown.Item>
                        <Dropdown.Item eventKey="13:30 - 14:00">13:30 - 14:00</Dropdown.Item>
                        <Dropdown.Item eventKey="14:00 - 14:30">14:00 - 14:30</Dropdown.Item>
                        <Dropdown.Item eventKey="14:30 - 15:00">14:30 - 15:00</Dropdown.Item>
                        <Dropdown.Item eventKey="15:00 - 15:30">15:00 - 15:30</Dropdown.Item>
                        <Dropdown.Item eventKey="15:30 - 16:00">15:30 - 16:00</Dropdown.Item>
                        <Dropdown.Item eventKey="16:00 - 16:30">16:00 - 16:30</Dropdown.Item>
                        <Dropdown.Item eventKey="16:30 - 17:00">16:30 - 17:00</Dropdown.Item>
                        <Dropdown.Item eventKey="17:00 - 17:30">17:00 - 17:30</Dropdown.Item>
                        <Dropdown.Item eventKey="17:30 - 18:00">17:30 - 18:00</Dropdown.Item>
                        <Dropdown.Item eventKey="18:00 - 18:30">18:00 - 18:30</Dropdown.Item>
                        <Dropdown.Item eventKey="18:30 - 19:00">18:30 - 19:00</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                {isEditMode &&
                    <Dropdown className="flex-grow-1" style={{ maxWidth: '100%', marginTop: '20px' }} onSelect={handleStatusSelect}>
                        <Dropdown.Toggle
                            id="dropdown-basic"
                            className="py-3 rounded-4 border-dark w-100 custom-dropdown-toggle bg-transparent text-success d-flex align-items-center justify-content-between pl-1 mr-2"
                        >
                            <span className="mx-3" style={{ color: '#6C757D' }}>
                                {selectTiemsoltStatus ? 'Active' : 'Inactive'}
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ width: '100%', maxHeight: '300px', overflowY: 'auto' }}>
                            <Dropdown.Item eventKey="active">Active</Dropdown.Item>
                            <Dropdown.Item eventKey="inactive">Inactive</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>}
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Button type="submit" variant="primary" className="rounded-4" onClick={handleSubmit}>
                        {isEditMode ? "Update Time Slot" : "Add Time Slot"}
                    </Button>
                </div>
            </Form>
        </Stack>
    )


    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <span>
                    <Nav />
                    <div className="content-body">
                        <div className="container-fluid">
                            <div className="h-80">
                                <div className="d-flex justify-content-between ">
                                    <div className="mb-2">
                                        <h1 className="text-black fs-35 font-w600 mb-1">
                                            Appointment Slots
                                        </h1>
                                        <h6>
                                            {userPerPage} Rows per page
                                        </h6>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">

                                        <Form className="d-flex gap-2 w-100 justify-content-end">
                                            <Dropdown
                                                className="flex-grow-1" style={{ maxWidth: '150px' }}
                                            >
                                                <Dropdown.Toggle id="dropdown-basic" className="py-3 rounded-4 border-dark  w-100 custom-dropdown-toggle bg-transparent text-success">
                                                    <span className="mx-3" style={{ color: '#6C757D' }}>All</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey="All">All</Dropdown.Item>
                                                    <Dropdown.Item eventKey="active">Active</Dropdown.Item>
                                                    <Dropdown.Item eventKey="inactive">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Button variant="primary" className="rounded-4 py-3" onClick={() => handleShowModal()}>
                                                Add Time Slot
                                            </Button>
                                        </Form>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="table-responsive">
                                            <div
                                                id="example5_wrapper"
                                                className="dataTables_wrapper no-footer"
                                            >
                                                <table
                                                    className="table display mb-4 dataTablesCard card-table dataTable no-footer"
                                                    id="example5"
                                                    role="grid"
                                                    aria-describedby="example5_info"
                                                >
                                                    <thead>
                                                        <tr role="row">
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="ID: activate to sort column ascending"

                                                            >
                                                                Id
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Company: activate to sort column ascending"

                                                            >
                                                                Time Slot
                                                            </th>

                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Contact: activate to sort column ascending"

                                                            >
                                                                Status
                                                            </th>
                                                            <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="example5"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Contact: activate to sort column ascending"

                                                            >
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>{getTimeSlots()}</tbody>
                                                </table>
                                                <div className="d-flex align-items-center justify-content-between pb-2">
                                                    <div
                                                        className="dataTables_info"
                                                        id="example5_info"
                                                        role="status"
                                                        aria-live="polite"
                                                    >
                                                        Showing {pageVisted + 1}
                                                        of {total_pages} pages
                                                    </div>
                                                    <div
                                                        className="dataTables_paginate paging_simple_numbers"
                                                        id="example5_paginate"
                                                    >
                                                        <ReactPaginate
                                                            previousLabel={"Previous"}
                                                            nextLabel={"Next"}
                                                            pageCount={pageCount}
                                                            onPageChange={changePage}
                                                            containerClassName={"paginationBttns"}
                                                            previousLinkClassName={
                                                                "paginate_button previous previousBttn"
                                                            }
                                                            nextLinkClassName={
                                                                "paginate_button next nextBttn"
                                                            }
                                                            pageLinkClassName={"paginate_button mr-1 ml-1"}
                                                            disabledClassName={"paginationDisabled"}
                                                            activeClassName={"paginationActive"}
                                                            forcePage={pageNumber}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
            )}
            <CustomeModal
                title={isEditMode ? "Update Time Slot" : "Add Time Slot"}
                show={isModalOpen}
                onHide={handleCloseModal}
                content={timeslotsContent}
            />
        </>
    );
}

export default AppointmentSlots;