import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



export const FeaturedSlide = ({ cardsData }) => {
   const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 51000,
      cssEase: "linear",
      // color: "#f5f5f5",
      // backgroundColor:"#f5f5f5",
      responsive: [
         {
            breakpoint: 988,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               initialSlide: 2,
               // backgroundColor:"#f5f5f5",
            },
         },
         {
            breakpoint: 600,
            settings: {
               // color: "#f5f5f5",
               slidesToShow: 1,
               slidesToScroll: 1,
               // backgroundColor:"#f5f5f5",
            },
         },
      ],
   };


   return (
      <Slider {...settings} >
         {/* Appointments */}
         <div className="items mr-2">
            <div className="col-xl-12 col-xxl-12 col-sm-12">
               <div
                  className="card opacity-75 mb-0 pb-0"
                  style={{ backgroundColor: "#f3f4ba", height: '200px', color: '#838884' }}
               >
                  <div className="card-body mb-0">
                     <div className="media align-items-center">
                        <div className="media-body">
                           <p p className="fs-22 mb-2 ">Total Appointments</p>
                           <span
                              className="display-6 font-w500"
                              style={{ color: "#00b094" }}
                           >
                              {cardsData?.total_appointments}
                           </span>
                        </div>
                        <div className="position-absolute top-1 end-0 mt-4 me-2 p-2 rounded-circle text-success"
                           style={{ backgroundColor: "#e1e2a8" }}
                        >
                           {/* <svg width="40" height="40" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect opacity="0.14" x="7.5" y="12.5" width="45" height="35" rx="3" fill="#00B094" />
                              <path d="M7.5 22.5C7.5 22.5 14.25 30 30 30C45.75 30 52.5 22.5 52.5 22.5M19.5 47.5H40.5C44.7004 47.5 46.8006 47.5 48.4049 46.6825C49.8161 45.9635 50.9635 44.8161 51.6825 43.4049C52.5 41.8006 52.5 39.7004 52.5 35.5V24.5C52.5 20.2996 52.5 18.1994 51.6825 16.5951C50.9635 15.1839 49.8161 14.0365 48.4049 13.3175C46.8006 12.5 44.7004 12.5 40.5 12.5H19.5C15.2996 12.5 13.1994 12.5 11.5951 13.3175C10.1839 14.0365 9.0365 15.1839 8.31745 16.5951C7.5 18.1994 7.5 20.2996 7.5 24.5V35.5C7.5 39.7004 7.5 41.8006 8.31745 43.4049C9.0365 44.8161 10.1839 45.9635 11.5951 46.6825C13.1994 47.5 15.2996 47.5 19.5 47.5Z" stroke="#00B094" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                           </svg> */}

                           <svg width="40px" height="40px" viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg">
                              <path d="M182.99 146.2h585.14v402.29h73.14V73.06H109.84v877.71H512v-73.14H182.99z" fill="#00B094" />
                              <path d="M256.13 219.34h438.86v73.14H256.13zM256.13 365.63h365.71v73.14H256.13zM256.13 511.91h219.43v73.14H256.13zM731.55 585.06c-100.99 0-182.86 81.87-182.86 182.86s81.87 182.86 182.86 182.86c100.99 0 182.86-81.87 182.86-182.86s-81.86-182.86-182.86-182.86z m0 292.57c-60.5 0-109.71-49.22-109.71-109.71 0-60.5 49.22-109.71 109.71-109.71 60.5 0 109.71 49.22 109.71 109.71 0.01 60.49-49.21 109.71-109.71 109.71z" fill="#00B094" /><path d="M758.99 692.08h-54.86v87.27l69.39 68.76 38.61-38.96-53.14-52.66z" fill="#00B094" />
                           </svg>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {/* Today's Appointment */}
         <div className="items mr-2">
            <div className="col-xl-12 col-xxl-12 col-sm-12">
               <div
                  className="card opacity-75 mb-0 pb-0"
                  style={{ backgroundColor: "#ddbaf4", height: '200px', color: '#838884' }}
               >
                  <div className="card-body mb-0">
                     <div className="media align-items-center">
                        <div className="media-body">
                           <p className="fs-22 mb-2 text-truncate">Today's Appointments</p>
                           <span
                              className="display-6 font-w500"
                              style={{ color: "#00b094" }}
                           >
                              {cardsData?.today_appointments}
                           </span>
                        </div>
                        <div className="position-absolute top-1 end-0 mt-4 me-2 p-2 rounded-circle text-success"
                           style={{ backgroundColor: "#cba8e2" }}
                        >
                           {/* <svg width="40" height="40" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect opacity="0.14" x="7.5" y="12.5" width="45" height="35" rx="3" fill="#00B094" />
                              <path d="M7.5 22.5C7.5 22.5 14.25 30 30 30C45.75 30 52.5 22.5 52.5 22.5M19.5 47.5H40.5C44.7004 47.5 46.8006 47.5 48.4049 46.6825C49.8161 45.9635 50.9635 44.8161 51.6825 43.4049C52.5 41.8006 52.5 39.7004 52.5 35.5V24.5C52.5 20.2996 52.5 18.1994 51.6825 16.5951C50.9635 15.1839 49.8161 14.0365 48.4049 13.3175C46.8006 12.5 44.7004 12.5 40.5 12.5H19.5C15.2996 12.5 13.1994 12.5 11.5951 13.3175C10.1839 14.0365 9.0365 15.1839 8.31745 16.5951C7.5 18.1994 7.5 20.2996 7.5 24.5V35.5C7.5 39.7004 7.5 41.8006 8.31745 43.4049C9.0365 44.8161 10.1839 45.9635 11.5951 46.6825C13.1994 47.5 15.2996 47.5 19.5 47.5Z" stroke="#00B094" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                           </svg> */}

                           <svg width="40px" height="40px" viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg">
                              <path d="M182.99 146.2h585.14v402.29h73.14V73.06H109.84v877.71H512v-73.14H182.99z" fill="#00B094" />
                              <path d="M256.13 219.34h438.86v73.14H256.13zM256.13 365.63h365.71v73.14H256.13zM256.13 511.91h219.43v73.14H256.13zM731.55 585.06c-100.99 0-182.86 81.87-182.86 182.86s81.87 182.86 182.86 182.86c100.99 0 182.86-81.87 182.86-182.86s-81.86-182.86-182.86-182.86z m0 292.57c-60.5 0-109.71-49.22-109.71-109.71 0-60.5 49.22-109.71 109.71-109.71 60.5 0 109.71 49.22 109.71 109.71 0.01 60.49-49.21 109.71-109.71 109.71z" fill="#00B094" /><path d="M758.99 692.08h-54.86v87.27l69.39 68.76 38.61-38.96-53.14-52.66z" fill="#00B094" />
                           </svg>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {/* Pending */}
         <div className="items mr-2">
            <div className="col-xl-12 col-xxl-12 col-sm-12">
               <div
                  className="card opacity-75 mb-0 pb-0"
                  style={{ backgroundColor: "#cfe0c0", height: '200px', color: '#838884' }}
               >
                  <div className="card-body mb-0 p-3">
                     <div className="media align-items-center">
                        <div className="media-body">
                           <p className="fs-22 mb-2 text-truncate">Pending Appointments</p>
                           <span
                              className="display-6 font-w500"
                              style={{ color: "#00b094" }}
                           >
                              {cardsData?.pending_appointments}
                           </span>
                        </div>
                        <div className="position-absolute top-1 end-0 mt-3 me-2 p-2 rounded-circle text-success"
                           style={{ backgroundColor: "#c3e2a7" }}
                        >
                           {/* <svg width="40" height="40" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle opacity="0.14" cx="30.666" cy="40.5999" r="12.5" transform="rotate(1.16111 30.666 40.5999)" fill="#00B094" />
                              <path d="M30.9226 28.1023L21.33 7.90381L11.332 7.70117L22.1149 31.58M30.9226 28.1023L41.3259 8.30909L51.3238 8.51173L39.5823 31.9341M30.9226 28.1023C34.2819 28.1704 37.3548 29.6418 39.5823 31.9341M30.9226 28.1023C27.5634 28.0343 24.4334 29.3799 22.1149 31.58M39.5823 31.9341C41.8456 34.2632 43.236 37.4398 43.1668 40.8531C43.0269 47.7552 37.3182 53.2371 30.4161 53.0972C23.5139 52.9573 18.032 47.2486 18.1719 40.3465C18.2411 36.9332 19.7591 33.8155 22.1149 31.58" stroke="#00B094" stroke-width="2" stroke-linejoin="round" />
                           </svg> */}

                           <svg width="40px" height="40px" viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg">
                              <path d="M182.99 146.2h585.14v402.29h73.14V73.06H109.84v877.71H512v-73.14H182.99z" fill="#00B094" />
                              <path d="M256.13 219.34h438.86v73.14H256.13zM256.13 365.63h365.71v73.14H256.13zM256.13 511.91h219.43v73.14H256.13zM731.55 585.06c-100.99 0-182.86 81.87-182.86 182.86s81.87 182.86 182.86 182.86c100.99 0 182.86-81.87 182.86-182.86s-81.86-182.86-182.86-182.86z m0 292.57c-60.5 0-109.71-49.22-109.71-109.71 0-60.5 49.22-109.71 109.71-109.71 60.5 0 109.71 49.22 109.71 109.71 0.01 60.49-49.21 109.71-109.71 109.71z" fill="#00B094" /><path d="M758.99 692.08h-54.86v87.27l69.39 68.76 38.61-38.96-53.14-52.66z" fill="#00B094" />
                           </svg>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {/* Onboarded */}
         <div className="items mr-2">
            <div className="col-xl-12 col-xxl-12 col-sm-12">
               <div
                  className="card opacity-75 mb-0 pb-0"
                  style={{ backgroundColor: "#d4c1df", height: '200px', color: '#838884' }}
               >
                  <div className="card-body mb-0 p-3">
                     <div className="media align-items-center">
                        <div className="media-body">
                           <p className="fs-22 mb-2 mt-3">Onboarded Appointments</p>
                           <span
                              className="display-6 font-w500"
                              style={{ color: "#00b094" }}
                           >
                              {cardsData?.onboarded_appointments}
                           </span>
                        </div>
                        <div className="position-absolute top-1 end-0 mt-3 me-2 p-2 rounded-circle text-success"
                           style={{ backgroundColor: "#cba8e2" }}
                        >
                           {/* <svg width="40" height="40" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g opacity="0.14">
                                 <path d="M20.0201 13.7495C20.0201 17.2013 17.2218 19.9995 13.7701 19.9995C10.3183 19.9995 7.52007 17.2013 7.52007 13.7495C7.52007 10.2977 10.3183 7.49951 13.7701 7.49951C17.2218 7.49951 20.0201 10.2977 20.0201 13.7495Z" fill="#00B094" />
                                 <path d="M20.0195 46.2495C20.0195 49.7013 17.2213 52.4996 13.7695 52.4996C10.3178 52.4996 7.51953 49.7013 7.51953 46.2495C7.51953 42.7978 10.3178 39.9995 13.7695 39.9995C17.2213 39.9995 20.0195 42.7978 20.0195 46.2495Z" fill="#00B094" />
                                 <path d="M52.4988 46.2491C52.4988 49.7009 49.7006 52.4991 46.2488 52.4991C42.797 52.4991 39.9988 49.7009 39.9988 46.2491C39.9988 42.7973 42.797 39.9991 46.2488 39.9991C49.7006 39.9991 52.4988 42.7973 52.4988 46.2491Z" fill="#00B094" />
                              </g>
                              <path d="M13.75 20C17.2018 20 20 17.2018 20 13.75C20 10.2982 17.2018 7.5 13.75 7.5C10.2982 7.5 7.5 10.2982 7.5 13.75C7.5 17.2018 10.2982 20 13.75 20ZM13.75 20V40M13.75 40C10.2982 40 7.5 42.7982 7.5 46.25C7.5 49.7018 10.2982 52.5 13.75 52.5C17.2018 52.5 20 49.7018 20 46.25C20 42.7982 17.2018 40 13.75 40ZM46.25 40V21.75C46.25 18.9497 46.25 17.5496 45.705 16.48C45.2257 15.5392 44.4608 14.7743 43.52 14.295C42.4504 13.75 41.0503 13.75 38.25 13.75H30M46.25 40C49.7018 40 52.5 42.7982 52.5 46.25C52.5 49.7018 49.7018 52.5 46.25 52.5C42.7982 52.5 40 49.7018 40 46.25C40 42.7982 42.7982 40 46.25 40ZM30 13.75L36.25 20M30 13.75L36.25 7.5" stroke="#00B094" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                           </svg> */}

                           <svg width="40px" height="40px" viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg">
                              <path d="M182.99 146.2h585.14v402.29h73.14V73.06H109.84v877.71H512v-73.14H182.99z" fill="#00B094" />
                              <path d="M256.13 219.34h438.86v73.14H256.13zM256.13 365.63h365.71v73.14H256.13zM256.13 511.91h219.43v73.14H256.13zM731.55 585.06c-100.99 0-182.86 81.87-182.86 182.86s81.87 182.86 182.86 182.86c100.99 0 182.86-81.87 182.86-182.86s-81.86-182.86-182.86-182.86z m0 292.57c-60.5 0-109.71-49.22-109.71-109.71 0-60.5 49.22-109.71 109.71-109.71 60.5 0 109.71 49.22 109.71 109.71 0.01 60.49-49.21 109.71-109.71 109.71z" fill="#00B094" /><path d="M758.99 692.08h-54.86v87.27l69.39 68.76 38.61-38.96-53.14-52.66z" fill="#00B094" />
                           </svg>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Slider >
   );
};
