import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Nav from "../../../layouts/nav";
import { FeaturedSlide } from "./FeaturedSlide";
import { useDispatch, useSelector } from "react-redux";
import { dashboard } from "../../../../features/home/homeSlice";

const Home = () => {

  const dispatch = useDispatch();

  // user
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(dashboard());
  }, []);

  const { dashboardData, isLoading, isError } = useSelector((state) => state.home);
  const cardsData = dashboardData?.body
  const todayAppointments = dashboardData?.body?.today_appointments_docs

  return (
    <Fragment>
      <Nav />
      <div className="content-body">
        <div className="container-fluid">
          <h1 className="text-black">Welcome <span className="text-success">{user?.name ? user?.name : ""}</span></h1>
          {/* <h1 className="text-success">Jack Will</h1> */}

          {/* Upper cards */}
          <div className="col-xl-12 my-5">
            <FeaturedSlide cardsData={cardsData} />
          </div>
          <div className="row">
            {/* <Container fluid>
              <Row>
                <Col lg={6}>
                  <Card>
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <Card.Title><b>This Week</b></Card.Title>
                          <Card.Subtitle >
                            <h1 className="mb-2 text-success my-3">Hiring Status</h1>
                          </Card.Subtitle>
                        </div>
                        <Form className="d-flex">
                          <div className="date-picker-container ">
                            <ReactDatePicker
                              selected={startDatePicker}
                              onChange={(date) => setStartDatePicker(date)}
                              className="form-control rounded-3 w-75 border-dark float-end"
                              style={{ height: '30px' }}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="From"
                            />
                          </div>
                          <div className="date-picker-container mx-2">
                            <ReactDatePicker
                              selected={dueDate}
                              onChange={(date) => setDueDate(date)}
                              className="form-control rounded-3 w-75 border-dark "
                              dateFormat="dd/MM/yyyy"
                              placeholderText="To"
                            />
                          </div>
                        </Form>
                      </div>
                      <Bar data={barData} />
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card>
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <Card.Title><b>This Week</b></Card.Title>
                          <Card.Subtitle >
                            <h1 className="mb-2 text-success my-3">Job Starting</h1>
                          </Card.Subtitle>
                        </div>
                        <div>
                          <Form className="d-flex justify-content-end">
                            <div className="">
                              <ReactDatePicker
                                selected={startDatePicker}
                                onChange={(date) => setStartDatePicker(date)}
                                className="form-control rounded-3 w-75 border-dark float-end"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="From"
                              />
                            </div>
                            <div className=" mx-2">
                              <ReactDatePicker
                                selected={dueDate}
                                onChange={(date) => setDueDate(date)}
                                className="form-control rounded-3  w-75 border-dark "
                                dateFormat="dd/MM/yyyy"
                                placeholderText="To"
                              />
                            </div>
                          </Form>
                      
                        </div>
                      </div>
                      <Line data={lineData} />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>

            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-sm-11">
                  <div className="card wh-50">
                    <div className="card-header d-flex flex-wrap justify-content-between align-items-center">
                      <h4 className="fs-20 text-black mr-3 mb-3 mb-md-0">
                        <b>Job Stats</b>
                      </h4>
                      <div className="d-flex flex-wrap align-items-center">
                        {["No Shows", "Posted", "Ongoing", "Cancel by Worker", "Cancel by Employer", "Hired", "Complete"].map((label, index) => (
                          <div key={index} className="custom-control custom-switch toggle-switch text-right mr-3 mb-2" >
                            <input type="checkbox" className="custom-control-input" id={`customSwitch${index + 1}`} />
                            <label className="custom-control-label" htmlFor={`customSwitch${index + 1}`}>
                              {label}
                            </label>
                          </div>
                        ))}
                        <Form className="d-flex align-items-center ml-3 mb-2">

                          <ReactDatePicker
                            selected={startDatePicker}
                            onChange={(date) => setStartDatePicker(date)}
                            className="form-control rounded-3 border-dark bg-transparent mx-4 date-picker-custom"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="From"
                          />

                          <ReactDatePicker
                            selected={dueDate}
                            onChange={(date) => setDueDate(date)}
                            className="form-control rounded-3 border-dark bg-transparent date-picker-custom "
                            dateFormat="dd/MM/yyyy"
                            placeholderText="To"
                          />

                        </Form>
                      </div>
                    </div>
                    <div className="card-body" >
                      <>
                        <div style={{ height: '520px', width: '200%' }}>
                          <DualLine3 />
                        </div>
                      </>
                      <div className="d-flex flex-wrap align-items-center justify-content-center mt-3">
                        {[
                          { label: "No Shows", color: "#20127a" },
                          { label: "Posted", color: "#67088a" },
                          { label: "Hired", color: "#00b094" },
                          { label: "Complete", color: "#ffe711" },
                          { label: "Ongoing", color: "#817828" },
                          { label: "Cancel by Worker", color: "#ff0000" },
                          { label: "Cancel by Employer", color: "#000000" }
                        ].map((item, index) => (
                          <div key={index} className="fs-14 text-black mr-4 mb-2 d-flex align-items-center">
                            <svg className="mr-2" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect width="19" height="19" rx="9.5" fill={item.color} />
                            </svg>
                            {item.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <h1 className="mt-5 mb-3">
              <b>Today's Appointments</b>
            </h1>
            <div className="row">
              <div className="col-xl-12">
                <div className="table-responsive">
                  <div
                    id="example5_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      className="table display mb-4 dataTablesCard card-table dataTable no-footer"
                      id="example5"
                      role="grid"
                      aria-describedby="example5_info"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="ID: activate to sort column ascending"
                          >
                            Id.
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Company: activate to sort column ascending"
                            style={{ paddingLeft: '20px' }}
                          >
                            Image
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Type: activate to sort column ascending"
                          >
                            Name
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Type: activate to sort column ascending"
                          >
                            Email
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                          >
                            Appointment Date
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Postition: activate to sort column ascending"
                            style={{ paddingLeft: '26px', width: 180 }}
                          >
                            Slot
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                            style={{ paddingLeft: '23px' }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      {todayAppointments?.length > 0
                        ? <tbody>
                          {todayAppointments?.map((appointment, index) => (
                            <tr role="row" className="odd">
                              <td>{index + 1}.</td>
                              <td>
                                <img
                                  src={appointment.worker_image}
                                  alt=""
                                  width="50"
                                  height="50"
                                  className="rounded-circle"
                                />
                              </td>
                              <td>
                                <div className="media">
                                  <div className="media-body text-nowrap">
                                    <h6 className="fs-16 mb-0">
                                      {appointment?.worker_name}
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="media">
                                  <div className="media-body text-nowrap">
                                    <h6 className="textfs-16 mb-0">
                                      {appointment?.worker_email}
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="media">
                                  <div className="media-body text-nowrap">
                                    <h6 className="textfs-16 mb-0">
                                      {/* change date format into "yyyy-MM-dd" */}
                                      {
                                        new Date(appointment?.date).toLocaleDateString('en-GB')
                                      }

                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="media">
                                  <div className="media-body text-nowrap">
                                    <h6 className="textfs-16 mb-0">
                                      {appointment?.slot}

                                    </h6>
                                  </div>
                                </div>
                              </td>
                              {/* <td>
                              <div className="d-flex align-items-center text-center">
                                <Link
                                  className="btn btn-rounded btn-light btn-sm text-success"
                                  to="#"
                                >
                                  {'Active'}
                                </Link>
                              </div>
                            </td> */}
                              <td>
                                <div className="d-flex align-items-center text-center">
                                  <Link className="btn btn-rounded btn-light btn-sm text-warning" to="#" style={{ textTransform: "capitalize" }}>
                                    {appointment?.status}
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        :
                        <p className="text-center mt-3">
                          No Appointmens for today
                        </p>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
